import React from 'react'

function Button({ text, light = false, classes = '', onClick }) {
  return (
    <div className='hover:-translate-y-1 transition-all ease-in duration-200'>
    <button
    onClick={onClick}
    className={`rounded-[50px] py-5 px-8 ${classes} ${light? 'bg-transparent border-[1px] border-gradientBlue hover:bg-gradientBlue' : 'bg-gradientBlue shadow-2xl shadow-gradientBlue/55'} whitespace-nowrap font-inter sm:py-[17px] sm:px-[22px] text-white text-base sm:text-sm font-semibold left-4 transition-all ease-in duration-200 cursor-pointer`}>
      {text}
    </button>
    </div>
  )
}

export default Button
