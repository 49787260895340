import React, { useState } from "react";
import ReactPlayer from "react-player";
import Gradient from "../Gradient";
import { FaPlay } from "react-icons/fa6";
import { useInView } from "react-intersection-observer";

function VideoSection() {
  const { ref } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  return (
    <div className="bg-darkBlue w-full h-[533px] sm:h-[388px] flex justify-center items-center flex-col sm:px-6">
      <div ref={ref} className={`opacity-0 opacity-100 w-[1016px] h-[585px] sm:h-[290px] sm:w-auto relative overflow-hidden rounded-xl p-6 sm:p-3 -mt-[280px] sm:-mt-[180px]`}>
        <Gradient
          className={
            "w-[159px] h-[159px] rounded-full bg-orange blur-[130px] top-[0px] left-[0px]"
          }
        />
        <Gradient
          className={
            "w-[159px] h-[159px] rounded-full bg-gradientBlue blur-[130px] top-[40px] right-0"
          }
        />
        <Gradient
          className={
            "w-[159px] h-[159px] rounded-full bg-orange blur-[130px] bottom-[0px] right-[0px]"
          }
        />
        <Gradient
          className={
            "w-[159px] h-[159px] rounded-full bg-gradientBlue blur-[130px] bottom-[40px] left-0"
          }
        />
        <div className="relative z-10">
          <CustomVideoPlayer />
        </div>
      </div>
      <p className="font-inter font-medium text-[18px] text-center text-[#D2D2D5] capitalize my-[32px] sm:text-sm">
        Built on the standards of the modern web
      </p>
      <div className="flex w-[536px] justify-between items-center sm:flex-wrap sm:w-[270px] sm:gap-5 sm:justify-center">
        <img src={"/image 2.png"} alt="frame"/>
        <img src={"/image 8.png"} alt="frame"/>
        <img src={"/image 5.png"} alt="frame"/>
        <img src={"/image 7.png"} alt="frame"/>
        <img src={"/image 3.png"} alt="frame"/>
        <img src={"/image 4.png"} alt="frame"/>
        <img src={"/image 6.png"} alt="frame"/>
      </div>
    </div>
  );
}

export default VideoSection;

const CustomVideoPlayer = () => {
  const [playing, setPlaying] = useState(false);
  const [clicked, setClicked] = useState(false);

const handleClick = () => {
  setPlaying(!playing);
  if (!clicked) {
    setClicked(true);
  }
};

  return (
    <div className="roudned-xl h-[530px] sm:h-[265px] overflow-hidden group w-full rounded-xl">
      {/* ReactPlayer */}
      <ReactPlayer
        url="https://d1xxrktck7kph8.cloudfront.net/Intro-Video.mp4"
        width="100%"
        height="100%"
        style={{ borderRadius: "12px" }}
        playing={playing}
        controls
        config={{
          file: {
            attributes: {
              controlsList: 'nodownload'
            }
          }
        }}
      />
      {!clicked &&
      <div
        onClick={() => handleClick()}
        className="flex z-0 justify-center items-center w-full bg-transparent h-full absolute top-0 sm:scale-75"
      >
        {!playing && (
          <div className="bg-[#07070780] h-[96px] w-[96px] rounded-full p-4">
            <div className="bg-gradientBlue w-full h-full rounded-full flex justify-center items-center">
              <FaPlay className="text-white text-xl" />
            </div>
          </div>
        )}
      </div>
    }
    </div>
  );
};
