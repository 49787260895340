import React from 'react'
import Breadcrumb from '../components/Breadcrumb'
import Button from '../components/Button'
import Gradient from '../components/Gradient'
import Packages from '../components/Pricing/Packages'
import PackagesCompared from '../components/Pricing/PackagesCompared'
import Faqs from '../components/Pricing/Faqs'
import { useInView } from 'react-intersection-observer'
import { Helmet } from 'react-helmet'

function Pricing() {

    const { ref } = useInView({
        triggerOnce: true,
        threshold: 0,
    });

    const routes = [
        {
            name: 'Home',
            path: '/'
        },
        {
            name: 'Pricing',
            path: '/pricing'
        },
    ]
    return (
        <div ref={ref} >
            <Helmet>
                <title>DataEquity | Pricing</title>
                <meta name="description" content="DataEquity Pricing Page" />
                <meta name="keywords" content="Pricing" />
                <meta name="author" content="DataEquity" />
                <meta property="og:title" content="DataEquity | Pricing" />
                <meta property="og:description" content="DataEquity Pricing Page" />
            </Helmet>

            <Breadcrumb title='Pricing' routes={routes} />
            <div className='flex relative overflow-hidden justify-center items-center flex-col py-[100px] sm:py-8'>
                <div className='w-[1225px] sm:w-auto sm:px-6 relative z-10 flex flex-col gap-24 justify-start items-center sm:gap-8'>
                    <Packages />
                    <PackagesCompared />
                    <Faqs />

                    <div className='p-[60px] bg-[#FAFAFC] relative overflow-hidden rounded-[16px] flex flex-col justify-center items-center sm:py-9 sm:px-4 w-full gap-12'>
                        <h2 className='font-semibold font-poppins text-[48px] w-[800px] gradient-text text-center sm:w-auto sm:text-[28px]'>Start Your API Journey Today</h2>
                        <p className="text-base font-inter font-normal text-gray2 text-center w-[469px] -mt-6 sm:w-auto ">
                            Unlock the full potential of your data with innovative solutions designed to drive growth and efficiency.
                        </p>
                        <Button
                            text="Get Started For FREE"
                            onClick={() => window.open('https://studio.dataequity.io', '_blank')}
                        />

                        <Gradient
                            className={
                                "w-[159px] h-[159px] rounded-full bg-gradientBlue bottom-[-70px] blur-[120px] left-[150px] sm:bottom-[-100px] sm:left-[-100px]"
                            }
                        />
                        <Gradient
                            className={
                                "w-[159px] h-[159px] rounded-full bg-orange top-[-70px] blur-[120px] right-[-50px]  sm:top-[-100px] sm:right-[-100px]"
                            }
                        />
                    </div>
                </div>
                {/* <Gradient
                    className={
                        "w-[159px] h-[159px] rounded-full bg-gradientBlue bottom-[800px] blur-[150px] right-[0px]"
                    }
                />
                <Gradient
                    className={
                        "w-[159px] h-[159px] rounded-full bg-orange top-[1000px] blur-[150px] left-[-50px]"
                    }
                /> */}
            </div>
        </div>
    )
}

export default Pricing
