import React, { useEffect } from 'react'
import Header from './components/Header'
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom'
import Footer from './components/Footer'
import Home from './Pages/Home'
import Blog from './Pages/Blog'
// import WhitePaper from './Pages/WhitePaper'
import DECurator from './Pages/DECurator'
import DEMarketPlace from './Pages/DEMarketPlace'
import DEInfobot from './Pages/DEInfobot'
import About from './Pages/About'
import Contact from './Pages/Contact'
import Pricing from './Pages/Pricing'
import APIRecommender from './Pages/APIRecommender'
import BlogDetail from './Pages/BlogDetail'
import Terms from './Pages/Terms'
import PrivacyPolicy from './Pages/PrivacyPolicy'
import DEDataCenter from './Pages/DEDataCenter'
import DEEdge from './Pages/DEEdge'

function App() {
    const location = useLocation()

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [location.pathname])

  return (
    <>
        <Header />
        <Routes>
            <Route index element={<Home />} />
            <Route path="/home"  element={<Home />} />
            <Route path="/campaign"  element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/api-recommender" element={<APIRecommender />} />
            <Route path="/terms-of-use" element={<Terms />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/products" element={<Outlet />}>
            <Route index element={<Navigate to='/products/de-curator' />} />
            <Route path="de-data-center" element={<DEDataCenter />} />
            <Route path="de-edge" element={<DEEdge />} />
            <Route path="de-curator" element={<DECurator />} />
            <Route path="de-marketplace" element={<DEMarketPlace />} />
            <Route path="de-infobot" element={<DEInfobot />} />
          </Route>
          <Route path="/resources" element={<Outlet />}>
            <Route index element={<Navigate to='/resources/white-paper' />} />
            {/* <Route path="white-paper" element={<WhitePaper />} /> */}
            <Route path="blogs" element={<Outlet />} >
              <Route index element={<Blog />} />
              <Route path=":slug" element={<BlogDetail />} />
            </Route>
          </Route>
        </Routes>
        <Footer />
      </>
  )
}

export default App
