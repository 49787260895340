import React, { useState } from 'react'

function Faqs() {
    const [activeIndex, setActiveIndex] = useState(0);

    const faqData = [
      { question: 'Do you offer a free trial?', answer: 'Yes, we offer a lifetime free option for you to explore our basic features and see how they fit your needs.' },
      { question: 'What does each plan include?', answer: 'Each plan is tailored to meet specific needs. The Basic plan covers essential features and the Enterprise plan provides comprehensive solutions with premium support.' },
      { question: 'Can I customise the plans?', answer: 'Yes, please get in touch for custom plans.' },
      { question: 'Can I upgrade or downgrade my plan later?', answer: 'Yes, you can upgrade or downgrade your plan at any time to better suit your needs.' },
      { question: 'Are there any hidden fees?', answer: 'No, there are no hidden fees. All costs are clearly outlined in each plan.' },
      { question: 'What support options are available?', answer: 'We offer a tiered customer support for all plans. The Enterprise plan includes dedicated account management and priority support.' },
      { question: 'Can I share the API with my users?', answer: 'Yes, you can share the API endpoints with your users and gather their feedback.' },
      { question: 'Can I use this for my production workloads?', answer: 'Yes, our enterprise plan is production ready.' },
  ];

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className='flex flex-col gap-14 sm:gap-8'>
    <div className='flex gap-[16px] flex-col w-full justify-center items-center'>
        <h2 className='font-bold gradient-text font-poppins text-[48px] leading-[57.6px] sm:text-[28px] capitalize text-center w-[609px] sm:w-auto'>
        Answers to Your Most Common Questions
        </h2>
        <p className="text-base font-inter font-normal text-gray2 text-center w-[469px] sm:w-auto" >
        Find clear answers to help you understand our plans, features, and how to get started effortlessly.
        </p>
    </div>

    <div className="w-[1016px] flex flex-col gap-5 sm:gap-3 sm:w-auto">
      {faqData.map((item, index) => (
        <div key={index} className="border border-[#E9E9EA] rounded-[12px] shadow-xs">
          <button
            onClick={() => toggleAccordion(index)}
            className="w-full text-left flex justify-between items-center p-6 sm:p-4 focus:outline-none"
          >
            <span className="text-[20px] font-inter text-black2 font-medium">{item.question}</span>
            <svg
              className={`w-5 sm:w-4 sm:h-4 h-5 transform transition-transform ${
                activeIndex === index ? 'rotate-180' : ''
              }`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </button>
          <div
            className={`overflow-hidden transition-all ease-in-out duration-500 ${
              activeIndex === index ? 'max-h-screen' : 'max-h-0'
            }`}
          >
            <p className="px-6 pb-6 sm:px-4 sm:pb-4 text-base font-normal text-gray2">{item.answer}</p>
          </div>
        </div>
      ))}
    </div>
      
    </div>
  )
}

export default Faqs
