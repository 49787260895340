import React from "react";
import Gradient from "../Gradient";
import { useNavigate } from "react-router-dom";

function WeHaveLaunched() {
  const navigate = useNavigate();
  return (
    <div className="relative overflow-hidden flex flex-col w-full h-[396px] sm:h-auto sm:py-[50px] sm:px-6 justify-center items-center">
      <h2 className="leading relative z-10-[48.6px] font-poppins text-[36px] sm:text-[20px] sm:leading-[29px] whitespace-normal sm:w-auto font-semibold w-[936px] text-lightBlack">
        We have launched the{" "}
        <span onClick={() => window.open('https://recommender.dataequity.io', '_blank')} className="text-lightBlue italic underline cursor-pointer">API Recommender</span>{" "}
        service under the DE Infobot suite.
        <span className="text-gray">
          {" "}
          The API recommender will provide API recommendations for a given use
          case. Read our blog{" "}
          <span onClick={() => navigate('/api-recommender')} className="text-lightBlue italic underline cursor-pointer">here</span>
        </span>
      </h2>
      <Gradient
        className={
          "w-[159px] h-[159px] rounded-full bg-orange blur-[100px] top-[-70px] right-[-70px] sm:riight-[-120px] sm:top-[-120px]"
        }
      />
      <Gradient
        className={
          "w-[159px] h-[159px] rounded-full bg-gradientBlue blur-[100px] left-[-120px] sm:bottom-[-140px] sm:left-[-130px] "
        }
      />
    </div>
  );
}

export default WeHaveLaunched;
