/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Breadcrumb from '../components/Breadcrumb'
import BlogCard from '../components/Blog/BlogCard'
import { useInView } from 'react-intersection-observer';
import Loader from '../components/Loader';
import { Helmet } from 'react-helmet';

function Blog() {

    const { ref } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const routes = [
        {
            name: 'Home',
            path: '/'
        },
        {
            name: 'Resources',
            path: '/resources'
        },
        {
            name: 'Blogs',
            path: '/resources/blogs'
        }
    ]

    const [blogs, setBlogs] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const blogsPerPage = 5;
    const [totalPages, setTotalPages] = useState(0);
    const [paginatedBlogs, setPaginatedBlogs] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 782);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 782);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await fetch("https://api.dev.dataequity.io/website/blogs");
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data = await response.json();
                setTotalPages(Math.ceil(data?.length / blogsPerPage));
                setPaginatedBlogs(data?.slice((currentPage - 1) * blogsPerPage, currentPage * blogsPerPage));
                setBlogs(data);
            } catch (err) {
                console.error(err);
            }
        };

        fetchBlogs();
    }, []);


    useEffect(() => {
        window.scrollTo(0, 0);
        if (currentPage > 1)
            setPaginatedBlogs(blogs?.slice((currentPage - 1) * blogsPerPage, currentPage * blogsPerPage));
    }, [currentPage])
    return (
        <div ref={ref} >
            <Helmet>
                <title>DataEquity | Blog</title>
                <meta name="description" content="DataEquity Blog" />
                <meta property="og:title" content="DataEquity | Blog" />
                <meta property="og:description" content="DataEquity Blog" />
                <meta property="keywords" content="DataEquity Blog" />
            </Helmet>

            <Breadcrumb title='Blog' routes={routes} />
            <div className='py-[80px] flex justify-center items-center sm:py-8'>
                <div className='w-[1225px] flex flex-col gap-12 sm:gap-6 sm:w-auto sm:px-6'>
                    {blogs ? <>
                        {paginatedBlogs.map((blog, index) => (
                            <>
                                <BlogCard blog={blog.fields} key={index} first={index === 0} />
                                {index === 0 && paginatedBlogs.length > 1 && <hr className='w-full h-[2px] border-b-[1px] border-[#D2D2D5]' />}
                            </>
                        ))}
                        <div className='flex flex-col'>
                            <hr className='w-full h-[2px] border-b-[1px] border-[#D2D2D5]' />
                            <div className='flex justify-center mt-6'>
                                <button
                                    className={`px-4 py-2 mx-1 text-base font-semibold underline text-black2
                                    }`}
                                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                                    disabled={currentPage === 1}
                                >
                                    PREV
                                </button>
                                {[...Array(totalPages).keys()].map((_, index) => {
                                    if ((!isMobile && index < 7) || (isMobile && index < 3))
                                        return <button
                                            key={index}
                                            className={`w-[36px] h-[36px] flex justify-center text-black2 items-center mx-[6px] text-base font-semibold rounded-lg ${currentPage === index + 1
                                                ? 'bg-lightBlue text-white'
                                                : 'hover:text-white hover:bg-lightBlue'
                                                }`}
                                            onClick={() => setCurrentPage(index + 1)}
                                        >
                                            {index + 1}
                                        </button>
                                })}
                                <button
                                    className={`px-4 py-2 mx-1 text-base font-semibold underline text-black2
                                    }`}
                                    onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                                    disabled={currentPage === totalPages}
                                >
                                    NEXT
                                </button>
                            </div>
                        </div>
                    </>
                        :
                        <Loader />
                    }

                </div>
            </div>
        </div>
    )
}

export default Blog
