import React from 'react'

function Minus() {
  return (
    <svg width="15" height="2" viewBox="0 0 15 2" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.8584 1.01904H13.0848" stroke="#98A2B3" stroke-width="1.92453" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
  )
}

export default Minus
