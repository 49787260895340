import React from 'react';
import ToolTip from '../ToolTip';
import CheckMark from '../CheckMark';
import Minus from '../Minus';

function Table({ basic = false, team = false, enterprise =false }) {
    return (
        <div className='flex gap-0 w-full '>
            <div className="flex flex-col flex-1">
                <div className="flex justify-start gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <p className='font-poppins text-black2 text-sm font-medium'>Hosting</p>
                    <ToolTip text="Server hosting on Data Equity cloud or on the customer premise"/>
                </div>
                <div className="flex justify-start gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <p className='font-poppins text-black2 text-sm font-medium'>Users</p>
                    <ToolTip text="Number of users who can access the designs"/>
                </div>
                <div className="flex justify-start gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <p className='font-poppins text-black2 text-sm font-medium'>Number of API calls</p>
                    <ToolTip text="Number of API calls allowed"/>
                </div>
                <div className="flex justify-start gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <p className='font-poppins text-black2 text-sm font-medium'>Support</p>
                    <ToolTip text="Tiered support options for different plans"/>
                </div>
                <div className="flex justify-start gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <p className='font-poppins text-black2 text-sm font-medium'>Request Rate</p>
                    <ToolTip text="API rate limit applied during API execution"/>
                </div>
                <div className="flex justify-start gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <p className='font-poppins text-black2 text-sm font-medium'>Number of API Designs</p>
                    <ToolTip text="Number of API designs per licence"/>
                </div>
                <div className="flex justify-start gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <p className='font-poppins text-black2 text-sm font-medium'>API Security</p>
                    <ToolTip text="API security options like rate limiting, spike arrest, apikey, Basic auth etc."/>
                </div>
                <div className="flex justify-start gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <p className='font-poppins text-black2 text-sm font-medium'>Mock Data</p>
                    <ToolTip text="Ability to provide mock data for the API designs when they are deployed on Data Equity cloud."/>
                </div>
                <div className="flex justify-start gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <p className='font-poppins text-black2 text-sm font-medium'>Stateful Mocking</p>
                    <ToolTip text="Mocked data remembers all actions performed on them. Data added by a POST operation will be visible in the subsequent GET"/>
                </div>
                <div className="flex justify-start gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <p className='font-poppins text-black2 text-sm font-medium'>AI Enabled API design</p>
                    <ToolTip text="Ability to accept prompts and provide the matching OAS output"/>
                </div>
                <div className="flex justify-start gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <p className='font-poppins text-black2 text-sm font-medium'>Team Management and Collaboration</p>
                    <ToolTip text="Multiple team members can work on the same API design. Each action is logged and tracked."/>
                </div>

                <div className="flex justify-start gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <p className='font-poppins text-black2 text-sm font-medium'>SSO</p>
                    <ToolTip text="Single Sign On to different applications"/>
                </div>
                <div className="flex justify-start gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <p className='font-poppins text-black2 text-sm font-medium'>On Premise Hosting</p>
                    <ToolTip text="Hosting on clients on premise using dockerized containers"/>
                </div>
                <div className="flex justify-start gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <p className='font-poppins text-black2 text-sm font-medium'>Custom Mock Data</p>
                    <ToolTip text="Ability to add mock data that closely matches the customer's domain"/>
                </div>
                <div className="flex justify-start gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <p className='font-poppins text-black2 text-sm font-medium'>API Portal/marketplace</p>
                    <ToolTip text="Enable API commerce using an API Marketplace"/>
                </div>
                <div className="flex justify-start gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <p className='font-poppins text-black2 text-sm font-medium'>RBAC</p>
                    <ToolTip text="Role Based Access Control for users of the API Designer and the API Marketplace"/>
                </div>
                <div className="flex justify-start gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <p className='font-poppins text-black2 text-sm font-medium'>Custom Identity Providers</p>
                    <ToolTip text="Add Custom identity providers for enhanced security"/>
                </div>
                <div className="flex justify-start gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <p className='font-poppins text-black2 text-sm font-medium'>Token Management</p>
                    <ToolTip text="Token management using the API Marketplace"/>
                </div>
                <div className="flex justify-start gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <p className='font-poppins text-black2 text-sm font-medium'>API Monetization</p>
                    <ToolTip text="Support for API Monetization using the API Marketplace"/>
                </div>
                <div className="flex justify-start gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <p className='font-poppins text-black2 text-sm font-medium'>Integration with API Gateways</p>
                    <ToolTip text="Integrate with 3rd party API gateways to enable API Marketplace commerce"/>
                </div>
                <div className="flex justify-start gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <p className='font-poppins text-black2 text-sm font-medium'>Custom Domain</p>
                    <ToolTip text="Bring your own domain"/>
                </div>
                <div className="flex justify-start gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <p className='font-poppins text-black2 text-sm font-medium'>Advanced Analytics</p>
                    <ToolTip text="Advanced analytics for the API calls"/>
                </div>
            </div>

            <div className={`flex flex-col w-[321.4px] sm:w-auto ${basic? 'flex': 'sm:hidden'}`}>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <p className='font-inter text-sm font-normal text-[#475467]'>Cloud</p>
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <p className='font-inter text-sm font-normal text-[#475467]'>1</p>
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <p className='font-inter text-sm font-normal text-[#475467]'>Unlimited</p>
                </div>
                
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <p className='font-inter text-sm font-normal text-[#475467]'>Community</p>
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <p className='font-inter text-sm font-normal text-[#475467]'>10 APIs per second</p>
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <p className='font-inter text-sm font-normal text-[#475467]'>5</p>
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <CheckMark />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <CheckMark />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <CheckMark />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <Minus />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <Minus />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <Minus />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <Minus />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <Minus />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <Minus />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <Minus />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <Minus />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <Minus />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <Minus />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <Minus />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <Minus />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <Minus />
                </div>
            </div>


            {/* <div className={`flex flex-col w-[321.4px] sm:w-auto ${team? 'flex': 'sm:hidden'}`}>
            <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <p className='font-inter text-sm font-normal text-[#475467]'>Cloud</p>
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <p className='font-inter text-sm font-normal text-[#475467]'>5</p>
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <p className='font-inter text-sm font-normal text-[#475467]'>Unlimited</p>
                </div>
                
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <p className='font-inter text-sm font-normal text-[#475467]'>Email</p>
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <p className='font-inter text-sm font-normal text-[#475467]'>10 APIs per second</p>
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <p className='font-inter text-sm font-normal text-[#475467]'>50</p>
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <CheckMark />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <CheckMark />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <CheckMark />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <CheckMark />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <CheckMark />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <Minus />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <Minus />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <Minus />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <Minus />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <Minus />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <Minus />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <Minus />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <Minus />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <Minus />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <Minus />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <Minus />
                </div>
               
            </div> */}



            <div className={`flex flex-col w-[321.4px] sm:w-auto ${enterprise? 'flex': 'sm:hidden'}`}>
            <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <p className='font-inter text-sm font-normal text-[#475467]'>Dedicated / on-premise</p>
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <p className='font-inter text-sm font-normal text-[#475467]'>Unlimited</p>
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <p className='font-inter text-sm font-normal text-[#475467]'>Unlimited</p>
                </div>
                
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <p className='font-inter text-sm font-normal text-[#475467]'>Dedicated</p>
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <p className='font-inter text-sm font-normal text-[#475467]'>Unlimited</p>
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <p className='font-inter text-sm font-normal text-[#475467]'>Unlimited</p>
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <CheckMark />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <CheckMark />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <CheckMark />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                    <CheckMark />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                <CheckMark />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                <CheckMark />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                <CheckMark />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                <CheckMark />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                <CheckMark />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                <CheckMark />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                <CheckMark />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                <CheckMark />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                <CheckMark />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                <CheckMark />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                <CheckMark />
                </div>
                <div className="flex justify-center gap-1 items-center h-[61.58px] rounded-lg sm:h-[56px] odd:bg-[#F9FAFB] px-6 sm:px-4">
                <CheckMark />
                </div>
               
            </div>


        </div>
    );
}

export default Table;
