export const TABS = [
    {
      id: 1,
      heading: "Connect To Any Data Source",
      text: "Connect To Any Enterprise Data Source Using The DE API Curator And Get API Valuation And Recommendations.",
      image: "/image 9.png", 
    },
    {
      id: 2,
      heading: "Design The Contract",
      text: "Connect to any enterprise data source using the DE API Curator and get API valuation and recommendations.",
      image: "designer1.webp",
    },
    {
      id: 3,
      heading: "Add Policies",
      text: "Connect to any enterprise data source using the DE API Curator and get API valuation and recommendations.",
      image: "designer2.webp",
    },
    {
      id: 4,
      heading: "Connect to any API gateway",
      text: "Connect to any enterprise data source using the DE API Curator and get API valuation and recommendations.",
      image: "/designer3.webp",
    },
    {
      id: 5,
      heading: "Design your web pages",
      text: "Connect to any enterprise data source using the DE API Curator and get API valuation and recommendations.",
      image: "/CMS1.webp",
    },
    {
      id: 6,
      heading: "Design your API Models",
      text: "Connect to any enterprise data source using the DE API Curator and get API valuation and recommendations.",
      image: "/CMS2.webp",
    },
    {
      id: 7,
      heading: "API Catalogue",
      text: "Connect to any enterprise data source using the DE API Curator and get API valuation and recommendations.",
      image: "/CMS3.webp",
    },
    {
      id: 8,
      heading: "API Documentation",
      text: "Connect to any enterprise data source using the DE API Curator and get API valuation and recommendations.",
      image: "/CMS4.webp",
    },
    {
      id: 9,
      heading: "API Technical view",
      text: "Connect to any enterprise data source using the DE API Curator and get API valuation and recommendations.",
      image: "/CMS6.webp",
    },
    {
      id: 10,
      heading: "View the Pricing",
      text: "Connect to any enterprise data source using the DE API Curator and get API valuation and recommendations.",
      image:"/CMS5.webp",
    },
  ];