import React, { useEffect, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import Button from "./Button";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

function Header() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [infobar, setInfobar] = useState(false);
  const [products, setProducts] = useState(false);
  const [resourse, setResources] = useState(false);
  const productRef = React.createRef();
  const resoursesRef = React.createRef();
  const navigate = useNavigate()

  const [drawer, setDrawer] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (productRef.current && !productRef.current.contains(event.target)) {
        // alert("clicked")
        setProducts(false);
      }
      if (resoursesRef.current && !resoursesRef.current.contains(event.target)) {
        setResources(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [productRef, resoursesRef]);

  const location = useLocation()


  useEffect(() => {

    if (products) {
      setResources(false)
    }

  }, [products])
  useEffect(() => {

    if (resourse) {
      setProducts(false)
    }
  }, [resourse])

  useEffect(() => {
    setDrawer(false)
  }, [location])

  return (
    <div className="fixed top-0 w-full z-[99]">
      {infobar && (
        <div className="flex relative z-0 bg-[#000000] h-[43px] justify-center items-center gap-2">
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.8335 2.16666V11.3333H8.3335V18.8333L14.1668 8.83332H10.8335L14.1668 2.16666H5.8335Z"
              fill="#E98043"
            />
          </svg>
          <p className="font-inter text-sm sm:text-xs font-normal text-[#D2D2D5]">
            Get 20% Off on All API Management Plans!
          </p>
          <RxCross1
            onClick={() => setInfobar(false)}
            className="text-white absolute right-5 cursor-pointer"
          />
        </div>
      )}
      <div
        className={`relative z-10 px-[188px] sm:px-6 ${scrollPosition > 10 ? "bg-darkBlue" : ""
          } ${scrollPosition > 300 && infobar && !drawer ? "-mt-[43px]" : ""
          } transition-all ease-in duration-200`}
      >
        <div className={` py-[16px] sm:py-6 ${drawer ? '' : 'border-bottom'} !sm:border-none relative flex justify-between items-center`}>
          <Link to="/">
          <img
            src={"/Data Equity Inverted Color 2400x1800 (1) 1.png"} alt="pin" 
            className="w-[232px] sm:w-[154px]"
          />
          </Link>
          <div className={`flex gap-[13px] sm:fixed sm:right-[-500px] transition-all ease-in duration-150 sm:bg-white sm:w-full sm:h-screen sm:flex-col sm:p-6 ${drawer ? '  sm:left-0' : ''} ${infobar ? 'sm:top-[43px]' : 'sm:top-0'}`}>
            <NavLink
              to="/"
              className="font-inter font-medium text-base leading-4 pt-1 pb-1.5 px-2 rounded-2xl text-[#E9E9EA] hover:border-lightBlue border-transparent sm:border-t-0 sm:border-x-0 sm:rounded-none sm:text-gray2 sm:!border-b-[1px] sm:border-[#E9E9EA] sm:px-4 sm:py-3 border-[1px]"
            >
              Home
            </NavLink>
            <div
              ref={productRef}
              onClick={() => setProducts(!products)}
              className={`${location.pathname.includes('/products') || products ? 'active' : ''} cursor-pointer flex-wrap font-inter font-medium group relative text-base leading-4 pt-1 pb-1.5 px-2 rounded-2xl text-[#E9E9EA] hover:border-lightBlue border-transparent sm:border-t-0 sm:border-x-0 sm:rounded-none sm:text-gray2 sm:!border-b-[1px] sm:border-[#E9E9EA] sm:px-4 sm:py-3 border-[1px] flex justify-center items-center gap-1 sm:justify-start`}
            >
              Products <MdOutlineKeyboardArrowDown className="text-base" />{" "}
              {products &&
                <div className="absolute sm:relative sm:top-0 sm:border-none sm:bg-transparent sm:w-screen flex w-[250px] border border-gray1 bg-darkBlue top-10 left-0 z-50 rounded-lg flex-col py-2 overflow-hidden">
                  <NavLink
                    onClick={() => setProducts(!products)}
                    to="/products/de-edge" className="font-inter text-base font-medium hover:text-white sm:text-gray2 sm:font-normal text-gray1 px-4 py-2 w-full">
                    Edge
                  </NavLink>
                  <NavLink
                    onClick={() => setProducts(!products)}
                    to="/products/de-data-center" className="font-inter text-base font-medium hover:text-white sm:text-gray2 sm:font-normal text-gray1 px-4 py-2 w-full">
                    Datacenters
                  </NavLink>
                  <NavLink
                    onClick={() => setProducts(!products)}
                    to="/products/de-curator" className="font-inter text-base font-medium hover:text-white sm:text-gray2 sm:font-normal text-gray1 px-4 py-2 w-full">
                    Curator
                  </NavLink>
                  <NavLink
                    onClick={() => setProducts(!products)}
                    to="/products/de-marketplace" className="font-inter text-base font-medium hover:text-white sm:text-gray2 sm:font-normal text-gray1 px-4 py-2 w-full">
                    Marketplace
                  </NavLink>
                  <NavLink
                    onClick={() => setProducts(!products)}
                    to="/products/de-infobot" className="font-inter text-base font-medium hover:text-white sm:text-gray2 sm:font-normal text-gray1 px-4 py-2 w-full">
                    Infobots
                  </NavLink>
                  
                </div>}
            </div>
            <NavLink
              to="/about"
              className="font-inter font-medium text-base leading-4 pt-1 pb-1.5 px-2 rounded-2xl text-[#E9E9EA] hover:border-lightBlue border-transparent sm:border-t-0 sm:border-x-0 sm:rounded-none sm:text-gray2 sm:!border-b-[1px] sm:border-[#E9E9EA] sm:px-4 sm:py-3 border-[1px]"
            >
              About
            </NavLink>
            <NavLink
              to="/pricing"
              className="font-inter font-medium text-base leading-4 pt-1 pb-1.5 px-2 rounded-2xl text-[#E9E9EA] hover:border-lightBlue border-transparent sm:border-t-0 sm:border-x-0 sm:rounded-none sm:text-gray2 sm:!border-b-[1px] sm:border-[#E9E9EA] sm:px-4 sm:py-3 border-[1px]"
            >
              Pricing
            </NavLink>
            <div
              ref={resoursesRef}
              onClick={() => setResources(!resourse)}
              className={`${location.pathname.includes('/resources') || resourse ? 'active' : ''} cursor-pointer flex-wrap font-inter font-medium group relative text-base leading-4 pt-1 pb-1.5 px-2 rounded-2xl text-[#E9E9EA] hover:border-lightBlue border-transparent sm:border-t-0 sm:border-x-0 sm:rounded-none sm:text-gray2 sm:!border-b-[1px] sm:border-[#E9E9EA] sm:px-4 sm:py-3 border-[1px] flex justify-center items-center gap-1 sm:justify-start`}
            >
              Resources <MdOutlineKeyboardArrowDown className="text-base" />{" "}
              {resourse &&
                <div className="absolute sm:relative sm:top-0 sm:border-none sm:bg-transparent sm:w-screen flex w-[250px] border border-gray1 bg-darkBlue top-10 left-0 z-50 rounded-lg flex-col py-2 overflow-hidden">
                  {/* <NavLink
                    onClick={() => setResources(!resourse)}
                    to="/resources/white-paper" className="font-inter text-base font-medium hover:text-white sm:text-gray2 sm:font-normal text-gray1 px-4 py-2 w-full">
                    WhitePaper
                  </NavLink> */}
                  <NavLink
                    onClick={() => setResources(!resourse)}
                    to="/resources/blogs" className="font-inter text-base font-medium hover:text-white sm:text-gray2 sm:font-normal text-gray1 px-4 py-2 w-full">
                    Blogs
                  </NavLink>

                </div>}
            </div>

            <span className="sm:flex  hidden mt-8">
              <Button
                text={"Contact Sales"}
                onClick={() => navigate('/contact')}
                classes="!font-semibold !text-base !px-6 !py-4"
              />
            </span>
            {drawer &&
              <RxCross1
                onClick={() => setDrawer(false)}
                className="text-black2 text-xl absolute right-6 top-6 cursor-pointer hidden sm:flex"
              />}


          </div>
          <Button
            text={"Contact Sales"}
            light={true}
            onClick={() => navigate('/contact')}
            classes="!font-semibold !text-base !px-6 !py-4 sm:hidden"
          />

          <div
            onClick={() => setDrawer(!drawer)}
            className="hidden sm:flex">
            <svg
              width="20"
              height="14"
              viewBox="0 0 20 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 0.995C20 0.445 19.554 0 19.005 0H10.995C10.7311 7.86455e-09 10.478 0.10483 10.2914 0.291429C10.1048 0.478027 10 0.731109 10 0.995C10 1.25889 10.1048 1.51197 10.2914 1.69857C10.478 1.88517 10.7311 1.99 10.995 1.99H19.005C19.2689 1.99 19.522 1.88517 19.7086 1.69857C19.8952 1.51197 20 1.25889 20 0.995ZM20 7C20 6.45 19.554 6.005 19.005 6.005H0.995001C0.73111 6.005 0.478027 6.10983 0.29143 6.29643C0.104832 6.48303 0 6.73611 0 7C0 7.26389 0.104832 7.51697 0.29143 7.70357C0.478027 7.89017 0.73111 7.995 0.995001 7.995H19.005C19.2689 7.995 19.522 7.89017 19.7086 7.70357C19.8952 7.51697 20 7.26389 20 7ZM19.005 12.01C19.2689 12.01 19.522 12.1148 19.7086 12.3014C19.8952 12.488 20 12.7411 20 13.005C20 13.2689 19.8952 13.522 19.7086 13.7086C19.522 13.8952 19.2689 14 19.005 14H6.995C6.73111 14 6.47803 13.8952 6.29143 13.7086C6.10483 13.522 6 13.2689 6 13.005C6 12.7411 6.10483 12.488 6.29143 12.3014C6.47803 12.1148 6.73111 12.01 6.995 12.01H19.005Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
