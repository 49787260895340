import React from "react";
import Gradient from "../Gradient";
import Button from "../Button";
import { useNavigate } from "react-router-dom";

function AreYouReady() {
  const navigate = useNavigate()
  return (
    <div className="flex flex-col justify-center gap-[120px] items-center mt-[180px] pt-[120px] sm:px-6 sm:mt-[200px]">
      <div className="w-[1224px] h-[436px] bg-[#FAFAFC] rounded-[16px] p-[60px] gap-6 sm:gap-4 relative overflow-hidden flex flex-col items-center justify-center sm:w-auto sm:h-auto sm:py-[50px] sm:px-4">
        <Gradient
          className={
            "w-[159px] h-[159px] rounded-full bg-orange blur-[150px] top-[-40px] right-[-40px] sm:top-[-20px] sm:right-[-20px]"
          }
        />
        <Gradient
          className={
            "w-[159px] h-[159px] rounded-full bg-gradientBlue blur-[150px] bottom-[-40px] left-[120px] sm:bottom-[-20px] sm:left-[-20px]"
          }
        />

        <h2 className="gradient-text font-semibold font-poppins text-[48px] sm:text-[24px] leading-[57.6px] text-center capitalize">
        Are you ready to start your API journey and{" "}
        <span className="italic">leverage our powerful mocking capabilities and scale to enterprise-level solutions?</span>
        </h2>
        <p className="text-[16px] sm:text-sm font-inter font-normal text-center w-[690px] text-gray2 sm:w-auto">
          If you want to monetise your data, you need to understand what your
          APIs are, which data can enable its creation, and which are
          accessible. Gain visibility and track the equity of your data with DE.
        </p>
        <span className="mt-[18px]">
          <Button
            onClick={() => navigate('/pricing')}
            text={window.innerWidth < 768 ? "Request Demo": "Get Started For FREE"} />
        </span>
      </div>

    </div>
  );
}

export default AreYouReady;
