import React from 'react'
import Gradient from './Gradient'
import { Link } from 'react-router-dom';

function ContactForm({ about=false}) {
    const [data, setData] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [sent, setSent] = React.useState(false);
    const [failed, setFailed] = React.useState(false);
    const sendMail = async (e) => {
        e.preventDefault();
        setLoading(true);
        setFailed(false);
        setSent(false);
        try {
            const response = await fetch('https://api.dev.dataequity.io/website/sendmail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: data.name,
                    email: data.email,
                    phone: data.number ?? "",
                    organization: data.organization ?? "",
                    designation: data.designation ?? "",
                    message: data.message,
                }),
            });
            if(response.status === 200){
                setData({});
                document.querySelectorAll('input').forEach(input => input.value = '');
                document.querySelector('textarea').value = '';
                setSent(true);
                setTimeout(() => {
                    setSent(false);
                }, 5000)
            }
            else {
                setFailed(true);
                setTimeout(() => {
                    setFailed(false);
                }, 5000)
            }
            await response.json();
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    };

    return (
        <div className='w-full bg-white p-12 sm:px-4 sm:py-8 border border-gray3 shadow-xs rounded-[16px] relative overflow-hidden flex justify-center items-center flex-col'>
            <h2 className='font-poppins font-semibold text-[48px] gradient-text text-center sm:w-auto sm:text-[28px] relative z-20'>Get in Touch with <span className='italic'>Us</span></h2>
            { about ? 
                <p className='font-inter text-base font-normal text-gray2 text-center mt-4 w-[623px] sm:w-auto sm:text-[18px] relative z-20'>Contact us today for inquiries, support, or more information. We're here to assist you every step of the way.</p>
                :
                <p className='font-inter text-base font-semibold italic text-gray2 text-center mt-4 sm:text-[18px] relative z-20'>We will respond within 24 hours</p>
        }

            <form onSubmit={sendMail} className='mt-4 flex flex-col gap-6 relative z-20 w-full'>
                <div className='flex flex-col gap-[6px]'>
                    <label className='text-base font-poppins font-medium text-gray2'>Name</label>
                    <input
                        type='text'
                        required={true}
                        value={data.name}
                        name='name'
                        onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                        placeholder='Please enter name'
                        className='w-full px-4 py-3 rounded-lg border shadow-xs border-gray3 outline-none bg-white font-inter text-base placeholder:text-black3 text-black3'
                    />
                </div>
                <div className='flex flex-col gap-[6px]'>
                    <label className='text-base font-poppins font-medium text-gray2'>Email</label>
                    <input
                        required={true}
                        type='email'
                        value={data.email}
                        name='email'
                        onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                        placeholder='Please enter your email address'
                        className='w-full px-4 py-3 rounded-lg border shadow-xs border-gray3 outline-none bg-white font-inter text-base placeholder:text-black3 text-black3'
                    />
                </div>
               {!about && <>
                    <div className='flex flex-col gap-[6px]'>
                        <label className='text-base font-poppins font-medium text-gray2'>Phone</label>
                        <input
                            type='text'
                            name='number'
                            value={data.number}
                            onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                            placeholder='Please enter your phone'
                            className='w-full px-4 py-3 rounded-lg border shadow-xs border-gray3 outline-none bg-white font-inter text-base placeholder:text-black3 text-black3'
                        />
                    </div>
                    <div className='flex flex-col gap-[6px]'>
                        <label className='text-base font-poppins font-medium text-gray2'>Organization</label>
                        <input
                            type='text'
                            name='organization'
                            value={data.organization}
                            onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                            placeholder='Please enter name of your organization'
                            className='w-full px-4 py-3 rounded-lg border shadow-xs border-gray3 outline-none bg-white font-inter text-base placeholder:text-black3 text-black3'
                        />
                    </div>
                    <div className='flex flex-col gap-[6px]'>
                        <label className='text-base font-poppins font-medium text-gray2'>Designation</label>
                        <input
                            type='text'
                            name='designation'
                            value={data.designation}
                            onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                            placeholder='Please enter your designation'
                            className='w-full px-4 py-3 rounded-lg border shadow-xs border-gray3 outline-none bg-white font-inter text-base placeholder:text-black3 text-black3'
                        />
                    </div>
                </>}
                <div className='flex flex-col gap-[6px]'>
                    <label className='text-base font-poppins font-medium text-gray2'>Message</label>
                    <textarea
                        required={true}
                        type='text'
                        value={data.message}
                        name='message'
                        onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                        placeholder='Include a message...'
                        className='w-full px-4 py-3 rounded-lg h-[122px] border shadow-xs border-gray3 outline-none bg-white font-inter text-base placeholder:text-black3 text-black3'
                    />
                </div>
                <p className='font-inter text-base text-black2'>
                    We'll never share your email with anyone else. <Link to='/terms-of-use' className='text-gradientBlue italic'>Terms of use</Link>
                </p>
                <button
                    disabled={loading}
                    className={`disabled:cursor-wait disabled:opacity-70 rounded-[8px] -mt-2 py-5 px-8 bg-gradientBlue whitespace-nowrap font-inter sm:py-[17px] sm:px-[22px] text-white text-base sm:text-sm font-semibold left-4 transition-all ease-in duration-200 cursor-pointer`}>
                    Submit Message
                </button>
                {sent &&<p className='text-base text-center text-green-700 font-poppins font-medium'>Form submitted successfully!</p>}
                {failed &&<p className='text-base text-center text-red-700 font-poppins font-medium'>Could not send the email, please try again.</p>}
            </form>

            <Gradient
                className={
                    "w-[159px] h-[159px] rounded-full bg-orange blur-[130px] top-[0px] right-[-60px] sm:top-[-10px] sm:scale-75 sm:right-[-00px]"
                }
            />
            <Gradient
                className={
                    "w-[159px] h-[159px] rounded-full bg-gradientBlue blur-[130px] top-[0px] left-[-50px]  sm:top-[-10px] sm:scale-75  sm:left-[-60px]"
                }
            />
        </div>
    )
}

export default ContactForm
