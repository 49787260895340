import React from 'react'

function CheckMark() {
  return (
    <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.14108 10.6795L10.0279 13.5663L15.8015 7.79273M21.0939 10.6795C21.0939 15.994 16.7857 20.3022 11.4713 20.3022C6.15684 20.3022 1.84863 15.994 1.84863 10.6795C1.84863 5.36509 6.15684 1.05688 11.4713 1.05688C16.7857 1.05688 21.0939 5.36509 21.0939 10.6795Z" stroke="#039855" stroke-width="1.92453" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

  )
}

export default CheckMark
