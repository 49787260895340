import React from "react";
import Button from "./Button";
import Gradient from "./Gradient";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import { SiMedium } from "react-icons/si";
import { Link, useLocation, useNavigate } from "react-router-dom";

function Footer() {
  const location = useLocation()
  const navigate = useNavigate()
  return (
    <>
      <div className={`bg-darkBlue ${location.pathname === '/' && '-mt-[120px] sm:-mt-[170px]'}`}>
        <div className="relative z-0 flex flex-col justify-center items-center">
          <div className={`flex z-50 justify-between items-center w-[1224px] pb-12 ${location.pathname === '/'? 'pt-[240px] sm:pt-[220px]': location.pathname === '/about'? "pt-[450px] sm:pt-[500px] " : 'pt-[78px]'} border-bottom relative sm:w-[80%] sm:flex-col sm:items-center sm:justify-center sm:px-6`}>
            <h2 className="gradient-text2 text-[48px] font-poppins font-semibold capitalize sm:text-center sm:text-[24px] sm:mb-5">
              Get Started with <span className="italic">DE platform</span>
            </h2>
            <Button
              onClick={() => navigate('/pricing')}
              text="Get Started for FREE" />
          </div>
          <div className="relative z-10 w-[1224px] py-[60px] sm:py-[32px] sm:gap-[48px] gap-[70px] flex sm:w-auto sm:flex-col sm:px-4">
            <div className="w-[423px] gap-8 flex flex-col sm:w-auto">
              <img alt="DE"
                src={"/Data Equity Inverted Color 2400x1800 (1) 1.png"}
                className="w-[232px]"
              />
              <p className="font-inter font-normal text-base text-gray3 w-[374px] sm:w-auto">
                A secure and standard based external facing e-commerce portal
                for publishing business APIs. The Marketplace enables search &
                discovery of potential APIs that businesses can use to extend
                and support their innovative ideas.
              </p>
            </div>
            <div className="w-[232px] flex flex-col gap-7 sm:w-auto">
              <h2 className="text-white font-poppins font-semibold text-[20px]">
                Services
              </h2>
              <div className="flex flex-col gap-4">
                <a
                  href="/products/de-marketplace"
                  className="text-gray3 font-normal font-inter text-base hover:text-lightBlue hover:underline transition-all ease-in duration-100"
                >
                  API Monetization
                </a>
                <a
                  href="/products/de-infobot"
                  className="text-gray3 font-normal font-inter text-base hover:text-lightBlue hover:underline transition-all ease-in duration-100"
                >
                  Knowledge Bots
                </a>
                <a
                  href="/products/de-curator"
                  className="text-gray3 font-normal font-inter text-base hover:text-lightBlue hover:underline transition-all ease-in duration-100"
                >
                  Curate & Expose APIs
                </a>
                <a
                  href="/products/de-infobot"
                  className="text-gray3 font-normal font-inter text-base hover:text-lightBlue hover:underline transition-all ease-in duration-100"
                >
                  Al Driven
                </a>
                <a
                  href="/products/de-curator"
                  className="text-gray3 font-normal font-inter text-base hover:text-lightBlue hover:underline transition-all ease-in duration-100"
                >
                  Data Discovery & Valuation
                </a>
                <a
                  href="/products/de-curator"
                  className="text-gray3 font-normal font-inter text-base hover:text-lightBlue hover:underline transition-all ease-in duration-100"
                >
                  API Management
                </a>
                <a
                  href="/products/de-marketplace"
                  className="text-gray3 font-normal font-inter text-base hover:text-lightBlue hover:underline transition-all ease-in duration-100"
                >
                  API Marketplace
                </a>
              </div>
            </div>
            <div className="w-[232px] flex flex-col gap-7 sm:w-auto">
              <h2 className="text-white font-poppins font-semibold text-[20px]">
                Connect
              </h2>
              <div className="flex flex-col gap-4">
                <a
                  href="/contact"
                  className="text-gray3 font-normal font-inter text-base hover:text-lightBlue hover:underline transition-all ease-in duration-100"
                >
                  Contact Sales
                </a>
                <a
                  href="/terms-of-use"
                  className="text-gray3 font-normal font-inter text-base hover:text-lightBlue hover:underline transition-all ease-in duration-100"
                >
                  Terms and condition
                </a>
                <img
                  src={"/nvidia-inception.png"} alt="inception" 
                  className="h-[100px] w-auto relative z-0"
                />
              </div>
            </div>
            <div className="w-[103px] flex flex-col gap-7 sm:w-auto">
              <h2 className="text-white font-poppins font-semibold text-[20px]">
                Company
              </h2>
              <div className="flex flex-col gap-4">
                <a
                  href="/pricing"
                  className="text-gray3 font-normal font-inter text-base hover:text-lightBlue hover:underline transition-all ease-in duration-100"
                >
                  FAQ
                </a>
              </div>
            </div>
          </div>

          <img
            src={"/Pattern Bot.png"} alt="pin" 
            className="w-full h-[367px] absolute z-0 bottom-0 -translate-y-1 transform scale-y-[-1]"
          />
          <Gradient
            className={
              "w-[159px] h-[159px] rounded-full bg-orange blur-[130px] bottom-[40px] left-[68px] sm:hidden"
            }
          />
          <Gradient
            className={
              "w-[159px] h-[159px] rounded-full bg-gradientBlue blur-[130px] bottom-[40px] right-[40px] sm:right-[120px] sm:top-[250px]"
            }
          />
        </div>
      </div>
      <div className="relative z-10 bg-[#070707] flex justify-center items-center py-5 sm:px-4 sm:justify-start">
        <div className="w-[1224px] flex gap-[70px] sm:w-auto sm:flex-col sm:items-start sm:gap-6 ">
          <h2 className="text-gray3 font-inter font-semibold text-base w-[423px] sm:w-auto sm:text-sm">
            Copyright @ {new Date().getFullYear()} Data Equity. All rights reserved
          </h2>
          <div className="flex gap-6">
            <Link
              to='/terms-of-use'
              className="text-gray3 font-inter font-semibold text-base"
            >
              Terms of Use
            </Link>
            <Link to='/privacy-policy'
              href="#"
              className="text-gray3 font-inter font-semibold text-base"
            >
              Privacy Policy
            </Link>
          </div>
          <div className="flex flex-1 justify-end items-center gap-6">
            <a href="https://x.com/TheDataEquity" className="" target="_blank" rel="noreferrer">
                <FaXTwitter className="text-lightBlue hover:text-white transition-all ease-in duration-100 text-xl"/>
            </a>
            <a href="https://www.linkedin.com/company/thedataequity" className="" target="_blank" rel="noreferrer">
                <FaLinkedinIn className="text-lightBlue hover:text-white transition-all ease-in duration-100 text-xl"/>
            </a>
            <a href="https://dataequity.medium.com/" className="" target="_blank" rel="noreferrer">
                <SiMedium className="text-lightBlue hover:text-white transition-all ease-in duration-100 text-xl"/>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
