import React from 'react'
import Gradient from '../components/Gradient'
import Breadcrumb from '../components/Breadcrumb'
import { useInView } from 'react-intersection-observer';
import WeHaveLaunched from '../components/Home/WeHaveLaunched'
import { Helmet } from 'react-helmet';

function DEInfobot() {

    const { ref } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });
    const routes = [
        {
            name: 'Home',
            path: '/'
        },
        {
            name: 'Products',
            path: '/products'
        },
        {
            name: 'DE InfoBot',
            path: '/products/de-infobot'
        }
    ]
  return (
    <div ref={ref} >
        <Helmet>
            <title>DataEquity | InfoBot</title>
            <meta name="description" content="DataEquity InfoBot Page" />
            <meta name="keywords" content="Chatbot, Customer Service, InfoBot" />
            <meta name="author" content="DataEquity" />
            <meta property="og:title" content="DataEquity | DE InfoBot" />
            <meta property="og:description" content="DataEquity DE InfoBot Page" />
        </Helmet>

    <Breadcrumb title='DE InfoBot' routes={routes} />

    <div className='flex justify-center items-center py-[80px] sm:py-8 relative'>
        <div className='flex flex-col w-[1225px] gap-6 sm:gap-10 sm:w-auto sm:px-6'>
            <img src='/mmkmm.png' alt="pin" className='w-full h-[429.09px] sm:h-[218px] rounded-[20px] object-cover' />
            <h2 className='font-poppins text-[48px] font-bold gradient-text capitalize sm:text-[32px] sm:-mt-8'>Revolutionize your customer service <br/> with  <span className='italic'>DE InfoBot</span></h2>
            <p className='text-base font-inter font-normal text-gray2 sm:-mt-8'>
            Introducing a chatbot to revolutionize your customer service with 24/7 availability. This solution provides a cost-effective and convenient way to engage with customers and meet their queries regardless of time and location.
             </p>

            <div className="mt-6 flex justify-between items-center gap-20 sm:flex-col-reverse sm:w-auto sm:gap-0 h-[550px] sm:h-auto relative z-30">
            <div className="flex flex-col gap-4 w-[601px] sm:w-auto sm:gap-2">
                <h2 className="font-bold text-black2 font-poppins text-[36px] sm:text-[24px]">
                    Seamless Integration
                </h2>
                <p className="text-base font-inter font-normal text-gray2">
                    Integrating a chatbot into your customer service processes can vastly improve the customer experience. Seamless integration with your existing site and other CRM systems will make the chatbot a powerful business tool.
                </p>
                <p className="text-base font-inter font-normal text-gray2">
                    Our DE InfoBot is designed to work effortlessly with your current infrastructure, ensuring minimal disruption during implementation. Whether you use popular CRM systems like Salesforce, HubSpot, or custom-built solutions, our chatbot can be integrated smoothly.
                </p>
                <p className="text-base font-inter font-normal text-gray2">
                    Additionally, the DE InfoBot supports various communication channels such as web chat, social media platforms, and messaging apps, providing a unified customer service experience across all touchpoints.
                </p>
            </div>
                <div className='flex justify-center items-center w-1/2 sm:w-full'>
                <img src={"/mmkkmkm.png"} alt="pin" className="w-[382.63px] sm:w-auto" />
                </div>
            </div>

            <div className="flex justify-between items-center gap-20 sm:flex-col sm:w-auto sm:gap-8 h-[550px] sm:h-auto relative z-30">
                <img src={"/mkmkm.png"} alt="pin" className="w-[515px] sm:w-auto" />
                <div className="flex flex-col gap-4 w-[601px] sm:w-auto sm:gap-2">
                    <h2 className="font-bold text-black2 font-poppins text-[36px] sm:text-[24px]">
                        Customizable Responses
                    </h2>
                    <p className="text-base font-inter font-normal text-gray2">
                        The DE InfoBots can be trained to show your business data to the customers. With this level of controlled information input, you will be able to add checks and controls to the information being shared by the chatbot. Every information provided can be tested in the playground before releasing it for public consumption.
                    </p>
                    <p className="text-base font-inter font-normal text-gray2">
                        You can customize the responses to match your brand's voice and tone, ensuring a consistent customer experience. The chatbot can handle a wide range of queries, from simple FAQs to complex customer service issues.
                    </p>
                    <p className="text-base font-inter font-normal text-gray2">
                        Our platform also allows you to update and refine the chatbot's responses based on customer interactions and feedback, making it smarter and more efficient over time.
                    </p>
                    <p className="text-base font-inter font-normal text-gray2">
                        With DE InfoBot, you can provide personalized responses to your customers, enhancing their overall experience and satisfaction.
                    </p>
                </div>
            </div>

            <div className="mt-6 flex justify-between items-center gap-20 sm:flex-col-reverse sm:w-auto sm:gap-0 h-[550px] sm:h-auto relative z-30">
            <div className="flex flex-col gap-4 w-[531px] sm:w-auto">
                <h2 className="font-bold text-black2 font-poppins text-[36px] sm:text-[24px]">
                    Reports and Analytics
                </h2>
                <p className="text-base font-inter font-normal text-gray2">
                    All information processed by the chatbot will be recorded for audit and analysis. This information can be replayed later when the customer returns as well.
                </p>
                <p className="text-base font-inter font-normal text-gray2">
                    Our comprehensive analytics dashboard provides insights into customer interactions, helping you understand their needs and preferences better. You can track metrics such as response times, customer satisfaction, and frequently asked questions.
                </p>
                <p className="text-base font-inter font-normal text-gray2">
                    These insights enable you to continuously improve your customer service strategy and make data-driven decisions. With detailed reports, you can identify trends and patterns, allowing you to proactively address potential issues.
                </p>
                <p className="text-base font-inter font-normal text-gray2">
                    The DE InfoBot's analytics capabilities ensure that you have a clear understanding of your chatbot's performance and its impact on your business.
                </p>
            </div>
                <img src={"/jnbjdn.png"} alt="pin" className="w-[550px] sm:w-auto" />
            </div>

            <div className="mt-6 flex justify-between items-center gap-20 sm:flex-col sm:w-auto sm:gap-0 h-[550px] sm:h-auto relative z-30">
                <img src={"/Frame 2147226746.png"} alt="pin" className="w-[550px] sm:w-auto" />
                <div className="flex flex-col gap-4 w-[601px] sm:w-auto sm:gap-2">
                    <h2 className="font-bold text-black2 font-poppins text-[36px] sm:text-[24px]">
                        Friendly Interface
                    </h2>
                    <p className="text-base font-inter font-normal text-gray2">
                        We provide a friendly interface for model selection and training. A super-user can use the admin screens for repeated training and fine-tuning of data.
                    </p>
                    <p className="text-base font-inter font-normal text-gray2">
                        The intuitive design ensures that even users with minimal technical expertise can navigate and utilize the platform effectively. The interface is designed to be user-friendly, with clear instructions and helpful tooltips to guide you through each step.
                    </p>
                    <p className="text-base font-inter font-normal text-gray2">
                        Our platform also includes a comprehensive help center and support resources to assist you with any questions or issues you may encounter. Whether you're setting up your chatbot for the first time or making adjustments to improve its performance, our interface makes the process straightforward and hassle-free.
                    </p>
                    <p className="text-base font-inter font-normal text-gray2">
                        With DE InfoBot's friendly interface, you can easily manage and optimize your chatbot to meet your business needs and enhance customer satisfaction.
                    </p>
                </div>
            </div>

            <Gradient
                className={
                    "w-[159px] h-[159px] rounded-full bg-orange blur-[130px] top-[1000px] left-[-10px] sm:hidden"
                }
            />
            <Gradient
                className={
                    "w-[159px] h-[159px] rounded-full bg-gradientBlue blur-[130px] bottom-[1300px] right-[40px] sm:hidden"
                }
            />

        </div>
    </div>
    <WeHaveLaunched/>
</div>
  )
}

export default DEInfobot
