import React from "react";
import { Link } from "react-router-dom";

const BlogCard = ({ blog, first, isDetailPage = false }) => {

    const renderNode = (node) => {

        switch (node.nodeType) {
            case "paragraph":
                return (
                    <React.Fragment key={node.data.id}>
                        {renderText(node)}
                    </React.Fragment>
                );
            case "ordered-list":
                return (
                    <ol key={node.data.id} className="mb-6">
                        {node.content.map((item, index) => renderList(item))}
                    </ol>
                );
            case "unordered-list":
                return (
                    <ul key={node.data.id} className="mb-6">
                        {node.content.map((item, index) => renderList(item))}
                    </ul>
                );
            default:
                return null;
        }
    };

    const renderText = (node) => {
        return (
            <>
                {node.content.map((textNode, index) => {
                    const isBold = textNode?.marks?.some(mark => mark.type === "bold");
                    return (
                        <React.Fragment key={index}>
                            <span
                                className={`${textNode?.marks
                                    .map((mark) =>
                                        mark.type === "bold"
                                            ? "font-bold pb-2"
                                            : mark.type === "underline"
                                            ? "underline"
                                            : ""
                                    )
                                    .filter(Boolean)
                                    .join(" ")}`}
                            >
                                {textNode?.value}
                            </span>
                            <br />
                            {!isBold && (
                                    <br />
                            )}
                        </React.Fragment>
                    );
                })}
            </>
        );
    };
    

    const renderList = (node) => {

        if (node.nodeType === "list-item" && isDetailPage) {
            
            return (
                <div className="flex flex-col">
                    {node.content.map((item, index) => (
                        <div className="flex justify-start items-start gap-3 my-2">
                            <img src={"/Frame.png"} alt="pin" className="w-[24px] h-[24px]" />
                            <div className="">
                                {renderListItem(item)}
                            </div>
                        </div>
                    ))}
                </div>
            );
        }
    }
    const renderListItem = (node) => {
        return (
            <>
                {node.content.map((textNode, index) => (
                    <React.Fragment key={index}>
                        {textNode?.marks?.some(
                            (mark) => mark.type === "bold" || mark.type === "underline"
                        ) ? (
                            <>
                                <span
                                    className={textNode.marks
                                        .map((mark) =>
                                            mark.type === "bold"
                                                ? "font-bold"
                                                : mark.type === "underline"
                                                ? "underline"
                                                : ""
                                        )
                                        .filter(Boolean)
                                        .join(" ")}
                                >
                                    {textNode.value}
                                </span>
                            </>
                        ) : (
                            textNode.value
                        )}
                    </React.Fragment>
                ))}
            </>
        );
    };
    



    return (
        <div
            className={`w-full flex ${first ? 'flex-col sm:gap-8 ' : 'flex-row h-[466px] sm:gap-7'} sm:h-auto sm:flex-col gap-12 items-stretch`}
        >
            <div
                className={`${first ? 'w-full h-[429.09px] sm:h-[218px] ' : 'w-1/2 sm:h-[327px]'} sm:w-full overflow-hidden rounded-[20px]`}
            >
                <img
                    src="/fvamkmvdfmk.png"
                    alt="API Illustration"
                    className="w-full h-full object-cover"
                />
            </div>

            <div
                className={`${first ? 'w-full' : 'w-1/2'
                    } flex flex-col gap-4 justify-between sm:w-full relative`}
            >
                {/* Title */}
                <h2
                    className={`${first ? 'text-[48px] gradient-text sm:text-[32px]' : 'text-[36px] sm:text-[24px] text-black2'
                        } font-bold font-poppins`}
                    style={!first ? { flex: "0 0 auto" } : {}}
                >
                    {blog.title}
                </h2>

                {/* Metadata */}
                <div className="flex gap-4">
                    <p className="font-inter font-medium text-gray2 text-[18px] sm:text-base flex justify-start items-center gap-2">
                        <span className="w-2 h-2 rounded-full bg-gray2"></span>
                        <span className="leading-4">{blog.publishedDate}</span>
                    </p>
                </div>

                <div
                    className={`text-base font-inter font-normal relative text-gray2 text-left sm:w-auto overflow-hidden ${isDetailPage ? '' : `${first ? 'sm:line-clamp-[40]' : 'sm:line-clamp-[20]'} line-clamp-[12]`}`}
                    style={!first ? { flex: "1 1 auto", overflowY: "hidden" } : {}}
                >
                    {blog.content.content.map((item, index) => renderNode(item))}

                </div>
            
                {!isDetailPage && <Link to={`/resources/blogs/${blog.slug}`}
                    className="-mt-4 text-right text-gradientBlue font-semibold text-base italic cursor-pointer"
                >
                    Read More
                </Link>}
            </div>
        </div>
    );
};

export default BlogCard;
