import React from 'react'
import Breadcrumb from '../components/Breadcrumb'
import Gradient from '../components/Gradient'
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function DEEdge() {
    
    const { ref } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const routes = [
        {
            name: 'Home',
            path: '/'
        },
        {
            name: 'Products',
            path: '/products'
        },
        {
            name: 'DE Edge',
            path: '/products/de-edge'
        }
    ]
    return (
        <div ref={ref} >
            <Helmet>
                <title>DataEquity | Edge Data Centers</title>
                <meta name="description" content="DataEquity Edge Data Center Page" />
                <meta name="keywords" content="Edge Data Centers, AI, IoT, Cloud Computing" />
                <meta name="author" content="DataEquity" />
                <meta property="og:title" content="DataEquity | DE Edge" />
                <meta property="og:description" content="DataEquity DE Edge Page" />
            </Helmet>

            <Breadcrumb title='DE Edge' routes={routes} />

            <div className='flex justify-center items-center py-[80px] sm:py-8 relative'>
                <div className='flex flex-col w-[1225px] gap-6 sm:w-auto sm:px-6'>
                <img src='/mmkmm.png' alt="pin" className='w-full h-[429.09px] sm:h-[218px] rounded-[20px] object-cover' />
                    <h2 className='font-poppins text-[36px] font-bold text-black2 sm:text-[32px]'>Pioneering the Future of Edge Data Centers</h2>
                    <p className='text-base font-inter font-normal text-gray2'>
                        Data Equity is at the forefront of designing, building, and operating cutting-edge edge data centres, delivering low-latency, high-performance solutions tailored for the next generation of AI, IoT, and cloud computing. As an NVIDIA Inception partner, we bring AI-optimized infrastructure to life, enabling businesses to achieve up to 90% reduction in latency, 40% lower energy costs, and 50% faster deployment for mission-critical applications.
                        <br />
                        <br />
                        The digital revolution demands real-time processing. Traditional centralized data centres cannot keep up with the 400% increase in global AI workloads and the growing demand for ultra-fast 5G networks. Edge computing eliminates latency bottlenecks, reduces bandwidth costs by up to 80%, and ensures 99.99% uptime for seamless business operations.
                    </p>

                    <div className="mt-6 flex justify-between items-center gap-20 sm:flex-col-reverse sm:w-auto sm:gap-0 relative z-30">
                        <div className="flex flex-col gap-4 w-[601px] sm:w-auto">
                            <h2 className="font-bold text-black2 font-poppins text-[36px] sm:text-[24px]">
                            Custom Solutions for Maximum Efficiency
                            </h2>
                            <div className="flex justify-start items-start gap-3">
                                <img src={"/Frame.png"} alt="pin" className="w-[24px] h-[24px]" />
                                <p className="text-base font-medium font-inter text-gray2">
                                <b>AI-Driven Architecture:</b> Optimized for high-density GPU clusters, processing petabytes of data in real-time.
                                </p>
                            </div>
                            <div className="flex justify-start items-start gap-3">
                                <img src={"/Frame.png"} alt="pin" className="w-[24px] h-[24px]" />
                                <p className="text-base font-medium font-inter text-gray2">
                                <b>Scalability & Modularity:</b> Deploy edge solutions in weeks, not months, with customized infrastructure to meet dynamic business needs.
                                </p>
                            </div>
                            <div className="flex justify-start items-start gap-3">
                                <img src={"/Frame.png"} alt="pin" className="w-[24px] h-[24px]" />
                                <p className="text-base font-medium font-inter text-gray2">
                                <b>Energy-Efficient & Sustainable:</b> Reduce power consumption by up to 40% with liquid cooling, AI-driven energy management, and renewable integration.
                                </p>
                            </div>
                        </div>
                        <img src={"/Frame 2147226740.png"} alt="monitoring" className="w-[550px] sm:w-auto" />
                    </div>

                    <div className="flex justify-between items-center gap-20 sm:flex-col sm:w-auto sm:gap-0 relative z-30">
                        <img src={"/jnbjdn.png"} alt="automation" className="w-[550px] sm:w-auto" />
                        <div className="flex flex-col gap-4 w-[601px] sm:w-auto">
                            <h2 className="font-bold text-black2 font-poppins text-[36px] sm:text-[24px]">
                                Rapid Deployment, Future-Ready Infrastructure
                            </h2>
                            <div className="flex justify-start items-start gap-3">
                                <img src={"/Frame.png"} alt="pin" className="w-[24px] h-[24px]" />
                                <p className="text-base font-medium font-inter text-gray2">
                                <b>Prefabricated, Modular Data Centres:</b> Deploy 50% faster than traditional data centres, optimized for urban, industrial, and remote locations.
                                </p>
                            </div>
                            <div className="flex justify-start items-start gap-3">
                                <img src={"/Frame.png"} alt="pin" className="w-[24px] h-[24px]" />
                                <p className="text-base font-medium font-inter text-gray2">
                                <b>Seamless AI Hardware Integration:</b> Enhanced compute performance using NVIDIA GPUs, supporting AI inference with up to 10x efficiency gains.
                                </p>
                            </div>
                            <div className="flex justify-start items-start gap-3">
                                <img src={"/Frame.png"} alt="pin" className="w-[24px] h-[24px]" />
                                <p className="text-base font-medium font-inter text-gray2">
                                <b>5G & IoT-Ready Connectivity:</b> Supports sub-millisecond latency, ideal for autonomous systems, smart cities, and real-time analytics.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="mt-6 flex justify-between items-center gap-20 sm:flex-col-reverse sm:w-auto sm:gap-5 relative z-30">
                        <div className="flex flex-col gap-4 w-[601px] sm:w-auto">
                            <h2 className="font-bold text-black2 font-poppins text-[36px] sm:text-[24px]">
                                Operate: Intelligent, Resilient, Secure
                            </h2>
                            <div className="flex justify-start items-start gap-3">
                                <img src={"/Frame.png"} alt="pin" className="w-[24px] h-[24px]" />
                                <p className="text-base font-medium font-inter text-gray2">
                                <b>AI-Powered Monitoring & Automation:</b> Predictive analytics enable 30% reduction in downtime and 25% improvement in operational efficiency.
                                </p>
                            </div>
                            <div className="flex justify-start items-start gap-3">
                                <img src={"/Frame.png"} alt="pin" className="w-[24px] h-[24px]" />
                                <p className="text-base font-medium font-inter text-gray2">
                                <b>Edge Cybersecurity & Compliance:</b> Enterprise-grade security, encryption, and compliance frameworks ensure 100% data sovereignty.
                                </p>
                            </div>
                            <div className="flex justify-start items-start gap-3">
                                <img src={"/Frame.png"} alt="pin" className="w-[24px] h-[24px]" />
                                <p className="text-base font-medium font-inter text-gray2">
                                <b>24/7 Operations & Maintenance:</b> 99.99% uptime guarantee with AI-driven predictive maintenance and real-time monitoring.
                                </p>
                            </div>
                        </div>
                        <img src={"/Layer_1 (1).png"} alt="security" className="w-[515px] sm:w-auto" />
                    </div>

                    <h2 className='font-poppins text-[36px] font-bold text-black2 sm:text-[32px]'>Intelligent Use of Sustainable Energy</h2>
                    <p className='text-base font-inter font-normal text-gray2'>
                        Data Equity is committed to building a greener, more resilient future by integrating sustainable energy solutions into edge data center deployments. We partner with leading battery energy storage and hydrogen fuel cell technology providers to ensure continuous power availability while minimizing carbon footprint. Our innovative power management strategies optimize renewable energy integration, delivering up to 60% reduction in grid dependency and enabling self-sustaining, off-grid edge data centres for remote and high-demand applications.
                    </p>

                    <h2 className='font-poppins text-[36px] font-bold text-black2 sm:text-[32px]'>Industries We Power</h2>
                    <div className="flex justify-start items-start gap-3">
                        <img src={"/Frame.png"} alt="pin" className="w-[24px] h-[24px]" />
                        <p className="text-base font-medium font-inter text-gray2">
                        <b>Artificial Intelligence & Machine Learning:</b> Edge solutions that accelerate AI model training and inference by up to 300%.
                        </p>
                    </div>
                    <div className="flex justify-start items-start gap-3">
                        <img src={"/Frame.png"} alt="pin" className="w-[24px] h-[24px]" />
                        <p className="text-base font-medium font-inter text-gray2">
                        <b>5G & Telecommunications:</b> Enabling ultra-fast, decentralized networks with localized data processing, reducing backhaul costs by 50%.
                        </p>
                    </div>
                    <div className="flex justify-start items-start gap-3">
                        <img src={"/Frame.png"} alt="pin" className="w-[24px] h-[24px]" />
                        <p className="text-base font-medium font-inter text-gray2">
                        <b>Smart Cities & IoT:</b> Powering real-time analytics for urban infrastructure, smart grids, and autonomous systems, improving efficiency by 35%.
                        </p>
                    </div>
                    <div className="flex justify-start items-start gap-3">
                        <img src={"/Frame.png"} alt="pin" className="w-[24px] h-[24px]" />
                        <p className="text-base font-medium font-inter text-gray2">
                        <b>Healthcare & Life Sciences:</b> AI-driven diagnostics and remote patient monitoring with 95% accuracy and near-zero latency.
                        </p>
                    </div>
                    <div className="flex justify-start items-start gap-3">
                        <img src={"/Frame.png"} alt="pin" className="w-[24px] h-[24px]" />
                        <p className="text-base font-medium font-inter text-gray2">
                        <b>Financial Services:</b> Supporting high-frequency trading, fraud detection, and AI-driven risk analysis, enabling instant transaction processing.
                        </p>
                    </div>

                    <h2 className='font-poppins text-[36px] font-bold text-black2 sm:text-[32px]'>Why Choose Data Equity?</h2>
                    <div className="flex justify-start items-start gap-3">
                        <img src={"/Frame.png"} alt="pin" className="w-[24px] h-[24px]" />
                        <p className="text-base font-medium font-inter text-gray2">
                        <b>Trusted NVIDIA Inception Partner:</b> Leveraging next-generation AI hardware and software for unparalleled performance.
                        </p>
                    </div>
                    <div className="flex justify-start items-start gap-3">
                        <img src={"/Frame.png"} alt="pin" className="w-[24px] h-[24px]" />
                        <p className="text-base font-medium font-inter text-gray2">
                        <b>Investor-Ready Growth Model:</b> Scalable infrastructure with high ROI potential, built for rapid expansion.
                        </p>
                    </div>
                    <div className="flex justify-start items-start gap-3">
                        <img src={"/Frame.png"} alt="pin" className="w-[24px] h-[24px]" />
                        <p className="text-base font-medium font-inter text-gray2">
                        <b>Sustainability First:</b> Energy-efficient solutions that cut carbon emissions and optimize power usage effectiveness (PUE) to below 1.2.
                        </p>
                    </div>

                    <h2 className='font-poppins text-[36px] font-bold text-black2 sm:text-[32px]'>Partner with Us</h2>
                    <p className='text-base font-inter font-normal text-gray2'>
                        Data Equity is revolutionizing edge computing with high-performance, AI-driven infrastructure. Whether you’re an enterprise seeking ultra-low latency solutions or an investor looking for exponential growth opportunities, we invite you to explore the future with us.
                    </p>

                    <p className='text-base font-inter font-normal text-gray2'>
                        <Link to='/contact' className='text-gradientBlue italic'>Contact Us </Link>
                        Today and let’s build the edge together.
                    </p>

                    <Gradient
                        className={
                            "w-[159px] h-[159px] rounded-full bg-orange blur-[130px] top-[1000px] left-[-10px] sm:hidden"
                        }
                    />
                    <Gradient
                        className={
                            "w-[159px] h-[159px] rounded-full bg-gradientBlue blur-[130px] bottom-[800px] right-[40px] sm:hidden"
                        }
                    />

                </div>
            </div>
        </div>
    )
}

export default DEEdge
