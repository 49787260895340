import React, { useState } from 'react';

function ToolTip({ text }) {
  const [showText, setShowText] = useState(false);

  const handleClick = () => {
    setShowText(!showText);
  };

  return (
    <div onClick={handleClick} style={{ position: 'relative', display: 'inline-block', cursor: 'pointer' }}>
      <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.77389 6.75497C6.92471 6.32623 7.2224 5.9647 7.61424 5.73441C8.00607 5.50413 8.46676 5.41995 8.91472 5.49679C9.36267 5.57362 9.76898 5.80651 10.0617 6.15421C10.3544 6.50191 10.5146 6.94198 10.5139 7.39648C10.5139 8.6795 8.58936 9.32101 8.58936 9.32101M8.64068 11.887H8.64709M15.0558 8.6795C15.0558 12.2225 12.1836 15.0946 8.64068 15.0946C5.09772 15.0946 2.22559 12.2225 2.22559 8.6795C2.22559 5.13654 5.09772 2.2644 8.64068 2.2644C12.1836 2.2644 15.0558 5.13654 15.0558 8.6795Z" stroke="#98A2B3" stroke-width="1.28302" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      {showText && (
        <div style={{ position: 'absolute', top: '20px', left: '0', backgroundColor: 'white', border: '1px solid #ccc', padding: '5px', borderRadius: '4px', zIndex: 1, width: '250px', fontSize: '12px', color: '#666' }}>
        {text}
      </div>
      )}
    </div>
  );
}

export default ToolTip;