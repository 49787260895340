import React from "react";
import Gradient from "../Gradient";
import Button from "../Button";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import Type from "./Type";

function FlexiblePlatform() {
  const navigate = useNavigate()
  const { ref } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  return (
    <div className="bg-darkBlue h-[799px] sm:h-[637px] w-full relative overflow-hidden flex justify-center items-start pt-[170px] sm:pt-[120px] sm:px-6">
      <img alt="pin"
        src={"/Pattern Bot.png"}
        className="w-full h-[367px] absolute z-0 bottom-0 -translate-y-1 transform scale-y-[-1]"
      />
      <Gradient
        className={
          "w-[159px] h-[159px] rounded-full bg-orange blur-[130px] bottom-[0px] left-[0px]"
        }
      />
      <Gradient
        className={
          "w-[159px] h-[159px] rounded-full bg-gradientBlue blur-[130px] top-[80px] left-1/2 -translate-x-1/2"
        }
      />

      <div className="w-[1034px] relative z-10 flex flex-col gap-4 items-center justify-center">
        <div className="flex justify-center items-center">
          <svg
            width="99"
            height="22"
            viewBox="0 0 99 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              y1="11.75"
              x2="88.5"
              y2="11.75"
              stroke="url(#paint0_linear_5191_59)"
              stroke-width="1.5"
            />
            <g filter="url(#filter0_f_5191_59)">
              <circle cx="88.5" cy="11" r="4.5" fill="#F9C540" />
            </g>
            <defs>
              <filter
                id="filter0_f_5191_59"
                x="78"
                y="0.5"
                width="21"
                height="21"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="3"
                  result="effect1_foregroundBlur_5191_59"
                />
              </filter>
              <linearGradient
                id="paint0_linear_5191_59"
                x1="0"
                y1="13"
                x2="88.5"
                y2="13"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#F2B418" stop-opacity="0" />
                <stop offset="1" stop-color="#F2B418" />
              </linearGradient>
            </defs>
          </svg>
          <h2 className="text-orange italic font-medium text-[20px] font-poppins sm:text-base">
            <Type />
          </h2>
          <svg
            width="99"
            height="22"
            viewBox="0 0 99 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              y1="-0.75"
              x2="88.5"
              y2="-0.75"
              transform="matrix(-1 0 0 1 99 12.5)"
              stroke="url(#paint0_linear_5191_63)"
              stroke-width="1.5"
            />
            <g filter="url(#filter0_f_5191_63)">
              <circle
                cx="4.5"
                cy="4.5"
                r="4.5"
                transform="matrix(-1 0 0 1 15 6.5)"
                fill="#F9C540"
              />
            </g>
            <defs>
              <filter
                id="filter0_f_5191_63"
                x="0"
                y="0.5"
                width="21"
                height="21"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="3"
                  result="effect1_foregroundBlur_5191_63"
                />
              </filter>
              <linearGradient
                id="paint0_linear_5191_63"
                x1="0"
                y1="0.5"
                x2="88.5"
                y2="0.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#F2B418" stop-opacity="0" />
                <stop offset="1" stop-color="#F2B418" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <h2 ref={ref} className={`   gradient-text2 text-[56px] sm:text-[28px] text-center font-poppins font-semibold capitalize`}>
          Flexible platform for{" "}
          <span className="italic">
            Data Hosting,
            <br className="sm:hidden"/>
            <span>{" "}Design and Management</span>
          </span>
        </h2>
        <p className={`font-inter font-medium text-[18px] text-center text-[#D2D2D5] sm:w-[273px]`}>Deploy, visualize and mock your data with ease!</p>

        <div className="flex justify-center items-center gap-6 mt-6 sm:gap-3">
            <Button
                onClick={() => navigate('/pricing')}
                text="Get Started For FREE"/>
            <Button
                onClick={() => window.open('https://docs.dataequity.io/docs/intro', '_blank')}
                text="Read Docs" light={true} classes="w-[200px] sm:w-[121px]"/>
        </div>
      </div>
    </div>
  );
}

export default FlexiblePlatform;
