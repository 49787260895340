import React from 'react'
import Gradient from './Gradient'
import { Link, useLocation } from 'react-router-dom'
import { useInView } from 'react-intersection-observer'

function Breadcrumb({
    title,
    routes = [],
}) {
  const location = useLocation()
  const paths = location.pathname.split('/')
  const currentPath = paths[paths.length - 1]

  const { ref } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });


  
  return (
    <div
    ref={ref}
    className={`bg-darkBlue h-[322px] sm:h-[210px] relative flex justify-center sm:justify-start sm:px-6 items-center overflow-hidden`}>
         <img alt="pin" 
        src={"/Pattern Bot.png"}
        className="w-full h-[367px] absolute z-0 bottom-0 -translate-y-1 transform scale-y-[-1]"
      />
      <Gradient
        className={
          "w-[159px] h-[159px] rounded-full bg-orange blur-[130px] bottom-[9px] left-[9px]"
        }
      />

      <div className='pt-[120px] pb- flex flex-col gap-4 sm:gap-3 sm:pb-6 sm:w-full w-[1224px] relative z-50'>
            <h2 className='font-extrabold text-white text-[32px] capitalize font-poppins sm:text-[20px] w-[500px] sm:w-[50%] truncate'>{title}</h2>
            <div className='flex gap-3 breadcramp w-[800px] sm:w-[90%] truncate'>
                {routes.map((route, index) => (
                    <>
                    {index > 0 && <p className='text-gray1 text-[18px] font-medium font-inter sm:text-base'> / </p>}
                    <Link key={index} to={route.path} className={`${route.path.includes(currentPath)? 'active': ''} text-gray1 text-[18px] font-medium font-inter sm:text-base capitalize`}>{route.name}</Link>
                    </>
                ))}
            </div>
      </div>
  
    </div>
  )
}

export default Breadcrumb
