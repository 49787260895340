import React from 'react'
import Breadcrumb from '../components/Breadcrumb'
import { useInView } from 'react-intersection-observer';
import Button from '../components/Button';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function APIRecommender() {

    const { ref } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });
    const routes = [
        {
            name: 'Home',
            path: '/'
        },
        {
            name: 'API Recommender',
            path: '/api-recommender'
        },

    ]
    const navigate = useNavigate();
    return (
        <div ref={ref} >
            <Helmet>
                <title>DataEquity | API Recommender</title>
                <meta name="description" content="DataEquity | API Recommender" />
                <meta name="keywords" content="API Recommender, API, Recommender, DataEquity" />
                <meta property="og:title" content="DataEquity | API Recommender" />
                <meta property="og:description" content="DataEquity | API Recommender" />
                <meta property="og:type" content="website" />
            </Helmet>

            <Breadcrumb title='API Recommender' routes={routes} />
            <div className='flex justify-center items-center py-[100px] sm:py-8'>
                <div className='w-[1225px] sm:w-auto sm:px-6 flex flex-col gap-6 sm:gap-4'>
                    <p className='font-inter font-medium text-[18px] text-gray2'>Published on: Sun, 23 June 2024</p>
                    <h2 className='font-semibold text-[48px] font-poppins gradient-text sm:text-[32px]'>API Recommender</h2>
                    <p className="text-base font-inter font-normal text-gray2">
                        This project (API Recommender) aims to construct large-scale, high-quality API recommendations to facilitate the construction of powerful LLMs with API recommendation capability. We achieve this by collecting a high-quality API dataset from popular API sources and constructing a language model that will return API recommendations for a given use-case.
                    </p>
                    <p className="text-base font-inter font-normal text-gray2">
                        We have gathered over 16000 representational state transfer (REST) APIs from various sources of publicly available APIs.
                    </p>
                    <p className="text-base font-inter font-normal text-gray2">
                        The built-in API Retriever retrieves APIs for a given use-case.
                    </p>
                    <p className="text-base font-inter font-normal text-gray2">
                        API Recommender is intended solely for research and educational purposes and should not be construed as reflecting the opinions or views of the creators, owners, or contributors of this dataset.
                    </p>
                    <p className="text-base font-inter font-semibold italic text-gray2">
                        Citations:
                    </p>
                    <p className="text-base font-inter font-normal text-gray2">
                        {"@misc{qin2023toolllm, title={ToolLLM: Facilitating Large Language Models to Master 16000+ Real-world APIs}, author={Yujia Qin and Shihao Liang and Yining Ye and Kunlun Zhu and Lan Yan and Yaxi Lu and Yankai Lin and Xin Cong and Xiangru Tang and Bill Qian and Sihan Zhao and Runchu Tian and Ruobing Xie and Jie Zhou and Mark Gerstein and Dahai Li and Zhiyuan Liu and Maosong Sun}, year={2023}, eprint={2307.16789}, archivePrefix={arXiv}, primaryClass={cs.AI} }"}
                    </p>
                    <p className="text-base font-inter font-normal text-gray2">
                        {"@misc{qin2023tool, title={Tool Learning with Foundation Models}, author={Yujia Qin and Shengding Hu and Yankai Lin and Weize Chen and Ning Ding and Ganqu Cui and Zheni Zeng and Yufei Huang and Chaojun Xiao and Chi Han and Yi Ren Fung and Yusheng Su and Huadong Wang and Cheng Qian and Runchu Tian and Kunlun Zhu and Shihao Liang and Xingyu Shen and Bokai Xu and Zhen Zhang and Yining Ye and Bowen Li and Ziwei Tang and Jing Yi and Yuzhang Zhu and Zhenning Dai and Lan Yan and Xin Cong and Yaxi Lu and Weilin Zhao and Yuxiang Huang and Junxi Yan and Xu Han and Xian Sun and Dahai Li and Jason Phang and Cheng Yang and Tongshuang Wu and Heng Ji and Zhiyuan Liu and Maosong Sun}, year={2023}, eprint={2304.08354}, archivePrefix={arXiv}, primaryClass={cs.CL} }"}
                    </p>

                    <span className='mt-6'>
                        <Button
                            onClick={() => navigate('/pricing')}
                            text="Get Started For FREE" />
                    </span>
                </div>

            </div>
        </div>
    )
}

export default APIRecommender
