import React from 'react'
import Gradient from '../components/Gradient'
import Breadcrumb from '../components/Breadcrumb'
import { useInView } from 'react-intersection-observer';
import { Helmet } from 'react-helmet';

function DEMarketPlace() {

    const { ref } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });
    const routes = [
        {
            name: 'Home',
            path: '/'
        },
        {
            name: 'Products',
            path: '/products'
        },
        {
            name: 'DE Marketplace',
            path: '/products/de-marketplace'
        }
    ]
    return (
        <div ref={ref} >
            <Helmet>
                <title>DataEquity | API Marketplace</title>
                <meta name="description" content="DataEquity API Marketplace Page" />
                <meta name="keywords" content="API Marketplace, API Management, API Documentation, API Pricing, API Monetization" />
                <meta name="author" content="DataEquity" />
                <meta property="og:title" content="DataEquity | DE Marketplace" />
                <meta property="og:description" content="DataEquity DE Marketplace Page" />
            </Helmet>

            <Breadcrumb title='DE Curator' routes={routes} />

            <div className='flex justify-center items-center py-[80px] sm:py-8 relative'>
                <div className='flex flex-col w-[1225px] gap-6 sm:gap-4 sm:w-auto sm:px-6'>
                    <img src='/fvamkmvdfmk.png' alt="pin" className='w-full h-[429.09px] sm:h-[218px] rounded-[20px] object-cover' />
                    <h2 className='font-poppins text-[48px] font-bold gradient-text sm:text-[32px]'>A Marketplace for <span className='italic'>APIs</span></h2>
                    <p className='text-base font-inter font-normal text-gray2'>
                        Our API Marketplace will enable you to realize the value of your data exposed through APIs. We provide a customizable and extendable API marketplace to list, manage and monetize your APIs along with your the APIs provided your third-party ecosystem. You can use the self-service UI to onboard the APIs and manage your users and partners.
                        <br />
                        <br />
                        In the digital world, APIs are becoming an essential component of modern businesses. APIs are the building blocks that enable developers to create innovative and efficient solutions for their customers. However, with so many APIs available and new ones being created every day, discovering and integrating the right APIs can be a challenge.
                        <br />
                        <br />
                        That's where our product, a Marketplace for APIs, comes in. Our platform offers a vast library of APIs from different providers, making it easy for developers to find and integrate APIs into their applications. Our marketplace provides developers with a single platform where they can browse, compare, and purchase APIs from different providers, streamlining the integration process and saving time and resources.
                        <br />
                        <br />
                        Our platform also offers advanced search and filtering tools that enable developers to find APIs that match their specific requirements, whether it's for data analytics, social media, payments, or other use cases. Additionally, our platform provides businesses with access to valuable analytics and reporting tools that allow them to track API usage and performance, enabling them to optimize their API strategy and make data-driven decisions to drive growth.
                    </p>

                    <div className="mt-6 flex justify-between items-center gap-20 sm:flex-col-reverse sm:w-auto sm:gap-0 relative z-30">
                        <div className="flex flex-col gap-4 w-[601px] sm:w-auto">
                            <h2 className="font-bold text-black2 font-poppins text-[36px] sm:text-[24px]">
                                Features and Benefits
                            </h2>
                            <p className="text-base font-inter font-normal text-gray2">
                                API Marketplace offers a variety of features and benefits to its users. Your APIs can be easily discovered, understood and utilized by prospective customers. The intuitive UI will provide a great experience to the users in trying out the APIs and deciding on a purchase plan.
                            </p>
                            <p className="text-base font-inter font-medium italic text-gray2">
                                Here are some of the features and benefits of having an API marketplace:
                            </p>
                            <div className="flex justify-start items-start  gap-3">
                                <img src={"/Frame.png"} alt="pin" className="w-[24px] h-[24px]" />
                                <p className="text-base font-normal font-inter text-gray2">
                                    <span className='font-medium'>Offer a wide selection of APIs: {" "}</span>
                                    An API marketplace can offers a vast selection of APIs from different providers, making it easy for businesses and developers to find the right APIs for their needs.
                                </p>
                            </div>
                            <div className="flex justify-start items-start  gap-3 -mt-2">
                                <img src={"/Frame.png"} alt="pin" className="w-[24px] h-[24px]" />
                                <p className="text-base font-normal font-inter text-gray2">
                                    <span className='font-medium'>Simplified integration:  {" "}</span>
                                    An API marketplace provides a simplified integration process, with clear documentation and guidelines that make it easy for developers to integrate APIs into their applications.
                                </p>
                            </div>
                            <div className="flex justify-start items-start  gap-3 -mt-2">
                                <img src={"/Frame.png"} alt="pin" className="w-[24px] h-[24px]" />
                                <p className="text-base font-normal font-inter text-gray2">
                                    <span className='font-medium'>Flexibility: {" "}</span>
                                    An API marketplace offers businesses and developers the flexibility to choose from different pricing models, including subscription-based models, pay-as-you-go, and freemium models.
                                </p>
                            </div>
                            <div className="flex justify-start items-start  gap-3 -mt-2">
                                <img src={"/Frame.png"} alt="pin" className="w-[24px] h-[24px]" />
                                <p className="text-base font-normal font-inter text-gray2">
                                    <span className='font-medium'>Cost-effectiveness: {" "}</span>
                                    API marketplaces can help businesses and developers save money by providing access to the SaaS platform at lower costs than building it from scratch.
                                </p>
                            </div>
                            <div className="flex justify-start items-start  gap-3 -mt-2">
                                <img src={"/Frame.png"} alt="pin" className="w-[24px] h-[24px]" />
                                <p className="text-base font-normal font-inter text-gray2">
                                    <span className='font-medium'>Improved developer experience: {" "}</span>
                                    An API marketplace provides a better developer experience by providing advanced search and filtering tools, comprehensive documentation, and support resources.
                                </p>
                            </div>
                            <div className="flex justify-start items-start  gap-3 -mt-2">
                                <img src={"/Frame.png"} alt="pin" className="w-[24px] h-[24px]" />
                                <p className="text-base font-normal font-inter text-gray2">
                                    <span className='font-medium'>Faster time-to-market:  {" "}</span>
                                    By providing access to pre-built Portal, our API marketplace can help businesses and developers accelerate time-to-market and quickly launch new products and services.
                                </p>
                            </div>
                        </div>
                        <img src={"/Frame 2147226739.png"} alt="pin" className="w-[550px] sm:w-auto" />
                    </div>

                    <div className="flex justify-between items-center gap-20 sm:flex-col sm:w-auto sm:gap-0 relative z-30">
                        <img src={"/Frame 2147226740.png"} alt="pin" className="w-[550px] sm:w-auto" />
                        <div className="flex flex-col gap-4 w-[601px] sm:w-auto">
                            <h2 className="font-bold text-black2 font-poppins text-[36px] sm:text-[24px]">
                                API Documentation
                            </h2>
                            <p className="text-base font-inter font-normal text-gray2">
                                Documentation is the crucial part of creating an attractive marketplace. It should be clear concise and easily accessible to the users to better understand the API. We provide API Documentation tools like Swagger, Redoc, Graphiql, AsyncAPI etc to visualize and try out the APIs.
                            </p>
                            <p className="text-base font-inter font-normal text-gray2">
                                Our API documentation product includes a user-friendly interface that allows developers to create, edit, and publish documentation quickly and easily. We also provide customizable templates that enable businesses to brand their API documentation and make it consistent with their brand guidelines.
                            </p>
                            <p className="text-base font-inter font-normal text-gray2">
                                Our product includes advanced search and filtering tools that make it easy for developers to find the information they need quickly. We also provide code samples and examples that illustrate how to use the API effectively, as well as comprehensive technical specifications and usage guidelines.
                            </p>
                        </div>
                    </div>

                    <div className="mt-6 flex justify-between items-center gap-20 sm:flex-col-reverse sm:w-auto sm:gap-0 relative z-30">
                        <div className="flex flex-col gap-4 w-[601px] sm:w-auto">
                            <h2 className="font-bold text-black2 font-poppins text-[36px] sm:text-[24px]">
                                Pricing plans
                            </h2>
                            <p className="text-base font-inter font-normal text-gray2">
                                Depending upon the business priorities and policies, an enterprise can decide to provide Free, Freemium and Paid models for APIs. We make it easy to bundle APIs into API packages and track the purchase as well as usage of the APIs.
                            </p>
                            <p className="text-base font-inter font-normal text-gray2">
                                Our pricing plan product includes a user-friendly interface that makes it easy to create and modify pricing plans quickly and easily. We offer a range of pricing models, including subscription-based models, pay-as-you-go models, and freemium models, enabling businesses to choose the model that best fits their needs.
                            </p>
                            <p className="text-base font-inter font-normal text-gray2">
                                Our product also provides advanced pricing management options that enable businesses to monitor pricing performance, control access, and manage usage. We provide comprehensive analytics and reporting tools that help businesses to track pricing usage and performance, making it easy to make data-driven decisions that drive growth and profitability.
                            </p>
                            <p className="text-base font-inter font-normal text-gray2">
                                Our pricing plan product is designed to be highly customizable, with the ability to create custom pricing rules and models that meet the unique needs of each business. We offer a range of integration options, enabling businesses to seamlessly integrate our pricing plan product with their existing systems and workflows.
                            </p>

                        </div>
                        <img src={"/Frame 2147226741.png"} alt="pin" className="w-[550px] sm:w-auto" />
                    </div>

                    <div className="mt-6 flex justify-between items-center gap-20 sm:flex-col sm:w-auto sm:gap-0 relative z-30">
                        <img src={"/Frame 2147226742.png"} alt="pin" className="w-[550px] sm:w-auto" />
                        <div className="flex flex-col gap-4 w-[601px] sm:w-auto">
                            <h2 className="font-bold text-black2 font-poppins text-[36px] sm:text-[24px]">
                                Workflows and Controls
                            </h2>
                            <p className="text-base font-inter font-normal text-gray2">
                                We provide many pre-built workflows that allow seamless on-boarding and management of users, APIs and API products. All entities are managed as APIs and thus we allow API based content/entity onboarding and management
                            </p>
                            <p className="text-base font-inter font-normal text-gray2">
                                Our product includes a range of features and tools that enable businesses to create custom workflows and controls that meet the unique needs of their organization. We offer user-friendly interfaces that make it easy to manage the onboarding process, control access, and monitor usage.
                            </p>
                            <p className="text-base font-inter font-normal text-gray2">
                                Our product provides advanced security features that help businesses to protect their APIs and sensitive data. We offer authentication and authorization controls, as well as advanced access management tools that enable businesses to control who has access to their APIs and data.
                            </p>
                            <p className="text-base font-inter font-normal text-gray2">
                                Our product is highly customizable, with the ability to create custom workflows, rules, and controls that meet the unique needs of each business. We also provide comprehensive analytics and reporting tools that enable businesses to track developer usage and performance, making it easy to make data-driven decisions that drive growth and profitability.
                            </p>

                        </div>
                    </div>

                    <Gradient
                        className={
                            "w-[159px] h-[159px] rounded-full bg-orange blur-[130px] top-[1000px] left-[-10px] sm:hidden"
                        }
                    />
                    <Gradient
                        className={
                            "w-[159px] h-[159px] rounded-full bg-gradientBlue blur-[130px] bottom-[1300px] right-[40px] sm:hidden"
                        }
                    />

                </div>
            </div>
        </div>
    )
}

export default DEMarketPlace
