import React from "react";
import Gradient from "../Gradient";

function DECurator() {
  return (
    <div className="overflow-hidden relative flex justify-center items-center flex-col gap-[120px] sm:py-[50px] sm:gap-[50px] py-[120px] sm:px-6">
     
     <div className="w-[1,224px] flex justify-between items-center gap-20 sm:flex-col-reverse sm:w-auto sm:gap-5">
        <div className="flex flex-col gap-4 w-[601px] sm:w-auto">
          <h2 className="font-bold text-black2 font-poppins text-[36px] sm:text-[24px]">
            Powering the Future of Edge AI
          </h2>
          <p className="text-base font-inter font-normal text-gray2">
            As an NVIDIA Inception partner, Data Equity specializes in designing, building, 
            and operating high-performance edge data centres, reducing latency by up to 90% for AI, IoT, 
            and 5G applications. Our modular, AI-optimized infrastructure enables 50% faster deployment 
            and 40% lower energy consumption through advanced cooling and automation. Supporting industries 
            from finance to healthcare, we provide real-time analytics, secure processing, and scalable AI capabilities. 
            Backed by predictive monitoring and 24/7 operations, we ensure 99.99% uptime and superior data sovereignty 
            compliance. Invest in the edge revolution—partner with us today.
          </p>
          <p className="text-base font-inter font-normal text-gray2">
            Our data center offers comprehensive solutions for businesses to deploy,
            manage, and optimize their IT infrastructure. With our data center, you
            can benefit from advanced monitoring, robust security measures, and
            efficient resource management. Whether you're a small startup or a large
            enterprise, our data center services can help you achieve operational
            excellence and drive business growth.
          </p>
        </div>
        <img src={"/Group.png"} alt="automation" className="w-[550px] sm:w-auto" />
      </div>

      <div className="w-[1,224px] flex justify-between items-center gap-20 sm:flex-col sm:w-auto sm:gap-5">
        <img src={"/jnbjdn.png"} alt="pin" className="w-[500px] sm:w-auto" />
        <div className="flex flex-col gap-4 w-[601px] sm:w-auto">
          <h2 className="font-bold text-black2 font-poppins text-[36px] sm:text-[24px]">
            Data Equity Studio
          </h2>
          <p className="text-base font-inter font-normal text-gray2">
            Our innovative API designer empowers you to design, mock, and expose your APIs for third-party consumption, all on the cloud. This comprehensive solution includes:
          </p>
          <div className="flex justify-start items-start gap-3">
            <img src={"/Frame.png"} alt="pin" className="w-[24px] h-[24px]" />
            <p className="text-base font-medium font-inter text-gray2">
              API Design Studio for creating and refining your API specifications.
            </p>
          </div>
          <div className="flex justify-start items-start gap-3">
            <img src={"/Frame.png"} alt="pin" className="w-[24px] h-[24px]" />
            <p className="text-base font-medium font-inter text-gray2">
              Mocking Engine to simulate API responses and behavior.
            </p>
          </div>
          <div className="flex justify-start items-start gap-3">
            <img src={"/Frame.png"} alt="pin" className="w-[24px] h-[24px]" />
            <p className="text-base font-medium font-inter text-gray2">
              Expose your APIs to third-party developers and partners.
            </p>
          </div>
          <div className="flex justify-start items-start gap-3">
            <img src={"/Frame.png"} alt="pin" className="w-[24px] h-[24px]" />
            <p className="text-base font-medium font-inter text-gray2">
              No code platform for rapid prototyping and testing online.
            </p>
          </div>
          <p className="text-base font-inter font-normal text-gray2">
            Provides a versatile, transactional and self serviceable API
            studio that IT developers can use to develop and test APIs. Once
            ready, API developers can promote these APIs to live/production,
            hence significantly decreasing the time for go-live.
          </p>
        </div>
      </div>

      <div className="w-[1,224px] flex justify-between items-center gap-20 sm:flex-col-reverse sm:w-auto sm:gap-5">
        <div className="flex flex-col gap-4 w-[601px] sm:w-auto">
          <h2 className="font-bold text-black2 font-poppins text-[36px] sm:text-[24px]">
          Data Marketplace
          </h2>
          <p className="text-base font-inter font-normal text-gray2">
            A secure and standard based external facing e-commerce portal for
            publishing business APIs. The Marketplace enables search & discovery
            of potential APIs that businesses can use to extend and support
            their innovative ideas.
          </p>
          <p className="text-base font-inter font-normal text-gray2">
            Our API portal enables businesses to build, manage, and promote
            their APIs to internal and external developers. With our API portal,
            businesses can create a comprehensive developer experience that
            includes documentation, testing tools, code samples, and support
            resources. Our portal also provides robust analytics and monitoring
            capabilities that allow businesses to gain insights into API usage
            and performance, helping them optimize their API strategy. Whether
            you're a small startup or a large enterprise, our API portal product
            can help you accelerate your API adoption and drive business growth.
          </p>
        </div>
        <img src={"/Group2.png"} alt="pin" className="w-[507.36px]" />
      </div>

      <div className="w-[1,224px] flex justify-between items-center gap-20 sm:flex-col sm:w-auto sm:gap-5">
        <img src={"/Frame 2147226619.png"} alt="pin" className="w-[550px]" />
        <div className="flex flex-col gap-4 w-[601px] sm:w-auto sm:mt-[-40px]">
          <h2 className="font-bold text-black2 font-poppins text-[36px] sm:text-[24px]">
          Infobots
          </h2>
          <p className="text-base font-inter font-normal text-gray2">
            A knowledge mining solution that is a compendium of all the internal
            and external knowledge produced within an enterprise. It can be
            trained to learn your enterprise features and respond to the queries
            of your customers and partners. DE InfoBots are based on the popular
            LLM models.
          </p>
          <p className="text-base font-inter font-normal text-gray2">
            Our company specializes in providing a cutting-edge chatbot product
            that helps businesses automate their customer service and
            engagement. Our chatbot product uses advanced natural language
            processing (NLP) technology to understand customer inquiries and
            respond with personalized and accurate answers. Our chatbots can be
            integrated with various messaging platforms, such as Facebook
            Messenger, WhatsApp, and Slack, allowing businesses to reach their
            customers wherever they are. Our product also offers analytics and
            reporting features, enabling businesses to track chatbot performance
            and optimize their chatbot strategy. Whether you're a small business
            or a large enterprise, our chatbot product can help you improve
            customer experience, increase efficiency, and drive growth.
          </p>
        </div>
      </div>

      <div className="w-[1,224px] flex justify-between items-center gap-20 sm:flex-col sm:w-auto sm:gap-5">      
        <div className="flex flex-col gap-4 w-[601px] sm:w-auto">
          <h2 className="font-bold text-black2 font-poppins text-[36px] sm:text-[24px]">
            Customizations
          </h2>
          <p className="text-base font-inter font-normal text-gray2">
            Our knowledgeable team will work closely with your team to realize
            the power of your data and APIs. We work in a collaborative manner
            advising and aiding your team to align with the API practices while
            being aligned with your enterprise strategy. Many aspects of the out
            of the box solution can be modified to fit your organizational
            priorities
          </p>
          <p className="text-base font-inter font-normal text-gray2">
            With our platform, businesses can easily package and sell their APIs
            to developers, partners, and third-party organizations. Our
            monetization model allows businesses to charge for API access based
            on usage, subscriptions, or other customized pricing models.
          </p>
          <p className="text-base font-inter font-normal text-gray2">
            With our product, businesses can generate interactive and
            user-friendly documentation that makes it easy for developers to
            understand and integrate their APIs into their applications. Our
            product also includes advanced editing tools and version control
            features, enabling businesses to update and maintain their
            documentation easily. Additionally, our API documentation solution
            offers customizable templates, enabling businesses to create
            documentation that matches their brand guidelines.
          </p>
        </div>
        <img src={"/Group4.png"} alt="pin" className="w-[550px]" />
      </div>
    
      <Gradient
        className={
          "w-[159px] h-[159px] rounded-full bg-orange blur-[150px] bottom-[650px] right-[-120px]] sm:hidden "
        }
      />
      <Gradient
        className={
          "w-[159px] h-[159px] rounded-full bg-purple blur-[150px] bottom-[100px] sm:bg-gradientBlue left-[-70px] sm:left-auto sm:top-[-70px] sm:!right-[-120px]"
        }
      />

    </div>
  );
}

export default DECurator;
