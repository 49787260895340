import React from 'react'
import Breadcrumb from '../components/Breadcrumb'
import Gradient from '../components/Gradient'
import { useInView } from 'react-intersection-observer';
import { Helmet } from 'react-helmet';

function DEDataCenter() {

    const { ref } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const routes = [
        {
            name: 'Home',
            path: '/'
        },
        {
            name: 'Products',
            path: '/products'
        },
        {
            name: 'DE Data Center',
            path: '/products/de-data-center'
        }
    ]
    return (
        <div ref={ref} >
            <Helmet>
                <title>DataEquity | Data Center Management</title>
                <meta name="description" content="DataEquity Data Center Page" />
                <meta name="keywords" content="Data Center, Data Center Management, Data Center Operations, Edge Data Cenetr, NVIDIA Inception" />
                <meta name="author" content="DataEquity" />
                <meta property="og:title" content="DataEquity | DE Data Center" />
                <meta property="og:description" content="DataEquity DE Data Center Page" />
            </Helmet>

            <Breadcrumb title='DE Data Center' routes={routes} />

            <div className='flex justify-center items-center py-[80px] sm:py-8 relative'>
                <div className='flex flex-col w-[1225px] gap-6 sm:w-auto sm:px-6'>
                <img src='/mmkmm.png' alt="pin" className='w-full h-[429.09px] sm:h-[218px] rounded-[20px] object-cover' />
                    <h2 className='font-poppins text-[36px] font-bold text-black2 sm:text-[32px]'>Optimize Your Data Center Operations with DE Data Center</h2>
                    <p className='text-base font-inter font-normal text-gray2'>
                        DE Data Center is a comprehensive platform designed to streamline data center management, enhance operational efficiency, and ensure optimal performance. With its advanced monitoring tools, automated workflows, and robust security features, DE Data Center provides everything you need to manage your data center effectively.
                        <br />
                        <br />
                        Our platform offers real-time insights into your data center operations, enabling you to make data-driven decisions and proactively address potential issues. Whether you are managing a single data center or multiple facilities, DE Data Center provides the scalability and flexibility you need to meet your business requirements.
                    </p>

                    <div className="mt-6 flex justify-between items-center gap-20 sm:flex-col-reverse sm:w-auto sm:gap-0 relative z-30">
                        <div className="flex flex-col gap-4 w-[601px] sm:w-auto">
                            <h2 className="font-bold text-black2 font-poppins text-[36px] sm:text-[24px]">
                                Advanced Monitoring
                            </h2>
                            <p className="text-base font-inter font-normal text-gray2">
                                DE Data Center offers advanced monitoring tools that provide real-time visibility into your data center operations. Monitor key performance metrics, track resource utilization, and receive alerts for potential issues, ensuring that your data center runs smoothly and efficiently.
                            </p>
                            <p className="text-base font-inter font-normal text-gray2">
                                Our platform integrates with various monitoring systems and devices, allowing you to consolidate data from multiple sources and gain a comprehensive view of your data center's performance.
                            </p>
                        </div>
                        <img src={"/Frame 2147226740.png"} alt="monitoring" className="w-[550px] sm:w-auto" />
                    </div>

                    <div className="flex justify-between items-center gap-20 sm:flex-col sm:w-auto sm:gap-0 relative z-30">
                        <img src={"/jnbjdn.png"} alt="automation" className="w-[550px] sm:w-auto" />
                        <div className="flex flex-col gap-4 w-[601px] sm:w-auto">
                            <h2 className="font-bold text-black2 font-poppins text-[36px] sm:text-[24px]">
                                Automated Workflows
                            </h2>
                            <p className="text-base font-inter font-normal text-gray2">
                                DE Data Center automates routine tasks and workflows, reducing manual effort and minimizing the risk of human error. From provisioning resources to managing backups, our platform streamlines your data center operations and improves overall efficiency.
                            </p>
                            <p className="text-base font-inter font-normal text-gray2">
                                Our automation tools are highly customizable, allowing you to create workflows that align with your specific business processes and requirements.
                            </p>
                        </div>
                    </div>

                    <div className="mt-6 flex justify-between items-center gap-20 sm:flex-col-reverse sm:w-auto sm:gap-5 relative z-30">
                        <div className="flex flex-col gap-4 w-[601px] sm:w-auto">
                            <h2 className="font-bold text-black2 font-poppins text-[36px] sm:text-[24px]">
                                Robust Security
                            </h2>
                            <p className="text-base font-inter font-normal text-gray2">
                                Security is a top priority at DE Data Center. Our platform includes robust security features that protect your data and infrastructure from threats. With advanced encryption, access controls, and regular security audits, you can trust that your data center is secure.
                            </p>
                            <p className="text-base font-inter font-normal text-gray2">
                                Our platform also provides detailed security reports and analytics, enabling you to monitor security events and ensure compliance with industry standards and regulations.
                            </p>
                        </div>
                        <img src={"/Layer_1 (1).png"} alt="security" className="w-[515px] sm:w-auto" />
                    </div>

                    <Gradient
                        className={
                            "w-[159px] h-[159px] rounded-full bg-orange blur-[130px] top-[1000px] left-[-10px] sm:hidden"
                        }
                    />
                    <Gradient
                        className={
                            "w-[159px] h-[159px] rounded-full bg-gradientBlue blur-[130px] bottom-[800px] right-[40px] sm:hidden"
                        }
                    />

                </div>
            </div>
        </div>
    )
}

export default DEDataCenter
