import React from 'react'
import Breadcrumb from '../components/Breadcrumb'
import Gradient from '../components/Gradient'
import { useInView } from 'react-intersection-observer';
import Helmet from 'react-helmet';

function DECurator() {

    const { ref } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const routes = [
        {
            name: 'Home',
            path: '/'
        },
        {
            name: 'Products',
            path: '/products'
        },
        {
            name: 'DE Curator',
            path: '/products/de-curator'
        }
    ]
    return (
        <div ref={ref} >

            <Helmet>
                <title>DataEquity | API Curator</title>
                <meta name="description" content="DataEquity DE Curator Page" />
                <meta name="keywords" content="API Design, API Mocking, API Policies, Curator" />
                <meta name="author" content="DataEquity" />
                <meta property="og:title" content="DataEquity | DE Curator" />
                <meta property="og:description" content="DataEquity DE Curator Page" />
            </Helmet>

            <Breadcrumb title='DE Curator' routes={routes} />

            <div className='flex justify-center items-center py-[80px] sm:py-8 relative'>
                <div className='flex flex-col w-[1225px] gap-6 sm:w-auto sm:px-6'>
                    <img src='/Rectangle 51.png' alt="pin" className='w-full h-[429.09px] sm:h-[218px] rounded-[20px] object-cover' />
                    <h2 className='font-poppins text-[36px] font-bold text-black2 sm:text-[32px]'>Realize the Equity of Data using DE Curator</h2>
                    <p className='text-base font-inter font-normal text-gray2'>
                        DE Curator is a powerful platform designed to streamline Data Discovery, Valuation, API development and management. With its AI powered IAVF (Information Asset Valuation Framework) engine, a user-friendly interface and vast array of tools and integrations, it offers everything you need to valuate, create, test and monitor APIs.
                        <br />
                        <br />
                        DE Curator is a powerful platform designed to streamline Data Discovery, Valuation, API development and management. With its AI powered IAVF (Information Asset Valuation Framework) engine, a user-friendly interface and vast array of tools and integrations, it offers everything you need to valuate, create, test and monitor APIs.
                        <br />
                        <br />
                        That's where our product, DE Curator, comes in. Our product is designed to help businesses harness the power of APIs by providing a comprehensive set of tools that make it easy to discover, manage, and integrate APIs into their applications. With DE Curator, businesses can access a vast library of APIs from different providers, enabling them to find the best APIs for their needs quickly.
                    </p>

                    <div className="mt-6 flex justify-between items-center gap-20 sm:flex-col-reverse sm:w-auto sm:gap-0 relative z-30">
                        <div className="flex flex-col gap-4 w-[601px] sm:w-auto">
                            <h2 className="font-bold text-black2 font-poppins text-[36px] sm:text-[24px]">
                                Asset Valuation
                            </h2>
                            <p className="text-base font-inter font-normal text-gray2">
                                DE Curator offers a powerful data asset valuation tool that can help you asses your data equity and make data-driven decisions with ease. Take advantage of its automated solutions and real-time analytics to maximize business potential and minimize human interference
                            </p>
                            <p className="text-base font-inter font-normal text-gray2">
                                With our product, businesses can easily organize and catalog their API assets, making it easy for developers and partners to discover and use them. Our product also offers version control features, enabling businesses to track changes and updates to their API assets over time.
                            </p>
                        </div>
                        <img src={"/Layer_1.png"} alt="pin" className="w-[550px] sm:w-auto" />
                    </div>

                    <div className="flex justify-between items-center gap-20 sm:flex-col sm:w-auto sm:gap-0 relative z-30">
                        <img src={"/Frame 2147226725.png"} alt="pin" className="w-[550px] sm:w-auto" />
                        <div className="flex flex-col gap-4 w-[601px] sm:w-auto">
                            <h2 className="font-bold text-black2 font-poppins text-[36px] sm:text-[24px]">
                                API Documentation
                            </h2>
                            <p className="text-base font-inter font-normal text-gray2">
                                DE Curator is a perfect way to leverage the power of APIs for you business. Their integration as and API Documentation allow you to quickly and easily implement APIs into your workflow, giving you the power to extend your capabilities
                            </p>
                            <p className="text-base font-inter font-normal text-gray2">
                                Our product enables businesses to generate user-friendly and interactive documentation that makes it easy for developers to understand and integrate APIs into their applications. Our API documentation solution includes customizable templates, version control features, and advanced editing tools, enabling businesses to create and maintain documentation that matches their brand guidelines and standards. Additionally, our product offers analytics and reporting features that allow businesses to track documentation usage and performance, and make data-driven decisions to optimize their API strategy.
                            </p>
                        </div>
                    </div>

                    <div className="mt-6 flex justify-between items-center gap-20 sm:flex-col-reverse sm:w-auto sm:gap-5 relative z-30">
                        <div className="flex flex-col gap-4 w-[601px] sm:w-auto">
                            <h2 className="font-bold text-black2 font-poppins text-[36px] sm:text-[24px]">
                                Pricing and Delivery policies
                            </h2>
                            <p className="text-base font-inter font-normal text-gray2">
                                DE Curator provides powerful solutions to access data in a secure and cost-effective manner. Their pricing and delivery policies are designed to ensure reliable and timely delivery of services, without compromising on quality. Get the best out of your data and harness the power of DE Curator </p>
                            <p className="text-base font-inter font-normal text-gray2">
                                With our product, businesses can easily set and adjust pricing for their products and services, including customized pricing models based on usage, subscriptions, and other factors. Our platform also offers robust delivery policy management, enabling businesses to configure and optimize shipping and fulfillment options. Additionally, our product includes analytics and reporting tools that enable businesses to track pricing and delivery performance, and make data-driven decisions to improve customer experience and business growth.
                            </p>

                        </div>
                        <img src={"/Layer_1 (1).png"} alt="pin" className="w-[515px] sm:w-auto" />
                    </div>

                    <Gradient
                        className={
                            "w-[159px] h-[159px] rounded-full bg-orange blur-[130px] top-[1000px] left-[-10px] sm:hidden"
                        }
                    />
                    <Gradient
                        className={
                            "w-[159px] h-[159px] rounded-full bg-gradientBlue blur-[130px] bottom-[800px] right-[40px] sm:hidden"
                        }
                    />

                </div>
            </div>
        </div>
    )
}

export default DECurator
