import React from 'react'
import BuildFor from '../components/Home/BuildFor'
import AreYouReady from '../components/Home/AreYouReady'
import DesignedFor from '../components/Home/DesignedFor'
import HowDoesIt from '../components/Home/HowDoesIt'
import DECurator from '../components/Home/DECurator'
import VideoSection from '../components/Home/VideoSection'
import FlexiblePlatform from '../components/Home/FlexiblePlatform'
import WhyUs from '../components/Home/WhyUs'
import Cookies from '../components/Home/CookieConsent'
import { Helmet } from 'react-helmet'

function Home() {

  return (
    <>
    <Helmet>
      <title>DataEquity | Data management redefined</title>
      <meta name="description" content="DataEquity Home Page" />
      <meta name="keywords" content="Home" />
      <meta name="author" content="DataEquity" />
      <meta property="og:title" content="DataEquity | Home" />
      <meta property="og:description" content="DataEquity Home Page" />
    </Helmet>

    <FlexiblePlatform />
    <VideoSection />
    {/* <WeHaveLaunched /> */}
    <DECurator />
    <HowDoesIt />
    <WhyUs />
    <DesignedFor />
    <AreYouReady />
    <BuildFor />
    <Cookies />
  </>
  )
}

export default Home
