import React from "react";
import { Typewriter } from 'react-simple-typewriter';

function Type() {
  return (
    <Typewriter
        words={[
          "Data Center Operations",
          "API Design",
          "API Management",
          "API Marketplace",
          "API Monetization",
          "API Mocking",
          "AI Driven",
          "Data Modeling",
          "Rapid Prototyping",
          "Policy Management",
          "Collaboration"
        ]}
        cursor
        loop
        cursorStyle='|'
        typeSpeed={70}
        deleteSpeed={20}
        delaySpeed={1500}
    />
  );
}

export default Type;
