/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer';
import { useParams } from 'react-router-dom';
import Breadcrumb from '../components/Breadcrumb';
import BlogCard from '../components/Blog/BlogCard';
import Loader from '../components/Loader';
import { Helmet } from 'react-helmet';

function BlogDetail() {
    const { ref } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const { slug } = useParams();
    let forematedSlug = slug.replace(/-/g, ' ');

    const routes = [
        {
            name: 'Home',
            path: '/'
        },
        {
            name: 'Resources',
            path: '/resources'
        },
        {
            name: 'Blogs',
            path: '/resources/blogs'
        },
        {
            name: `${forematedSlug}`,
            path: `/resources/blogs/${slug}`
        }
    ]

    const [blog, setBlog] = useState(null);

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await fetch("https://api.dev.dataequity.io/website/blogs");
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data = await response.json();

                const _blog = data.find(blog => blog.fields?.slug === slug);

                setBlog(_blog?.fields);
            } catch (err) {
                console.error(err);
            }
        };

        fetchBlogs();
    }, []);
    return (
        <div ref={ref} >
            <Helmet>
                <title>DataEquity | {forematedSlug}</title>
                <meta name="description" content="DataEquity | {forematedSlug}" />
                <meta name="keywords" content="{forematedSlug}" />
                <meta property="og:title" content="DataEquity | {forematedSlug}" />
                <meta property="og:description" content="DataEquity | {forematedSlug}" />
            </Helmet>

            <Breadcrumb title={forematedSlug} routes={routes} />
            <div className='py-[80px] flex justify-center items-center sm:py-8'>
                <div className='w-[1225px] flex flex-col gap-12 sm:gap-6 sm:w-auto sm:px-6'>
                    {blog ?
                    <BlogCard blog={blog} first={true} isDetailPage={true}/>   
                    :
                    <Loader /> 
                }
                </div>
            </div>
        </div>
    )
}

export default BlogDetail
