import React from 'react'
import Breadcrumb from '../components/Breadcrumb'
import ContactForm from '../components/ContactForm'
import Gradient from '../components/Gradient'
import { useInView } from 'react-intersection-observer';
import { Helmet } from 'react-helmet';


function Contact() {

  const { ref } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
    const routes = [
        {
            name: 'Home',
            path: '/'
        },
        {
            name: 'Contact',
            path: '/contact'
        },
       
    ]
  return (
    <div ref={ref} >
      <Helmet>
        <title>DataEquity | Contact</title>
        <meta name="description" content="DataEquity Contact Page" />
        <meta name="keywords" content="Contact" />
        <meta name="author" content="DataEquity" />
        <meta property="og:title" content="DataEquity | Contact" />
        <meta property="og:description" content="DataEquity Contact Page" />
      </Helmet>

       <Breadcrumb title='Contact' routes={routes} />
       <div className='flex justify-center items-center py-[80px] sm:py-8 relative overflow-hidden'>
                <div className='w-[1225px] sm:w-auto sm:px-6 relative z-30'>
                        <ContactForm isContactPage={true}/>
                </div>  

                <Gradient
            className={
              "w-[159px] h-[159px] rounded-full bg-orange blur-[130px] bottom-[70px] left-[0px] sm:hidden"
            }
          />
       </div>
    </div>
  )
}

export default Contact
