import React from 'react'
import Breadcrumb from '../components/Breadcrumb'
import Gradient from '../components/Gradient'
import ContactForm from '../components/ContactForm'
import { useInView } from 'react-intersection-observer';
import { Helmet } from 'react-helmet';

function About() {
  const { ref } = useInView({
    triggerOnce: true,
    threshold: 0,
  });


  const routes = [
    {
      name: 'Home',
      path: '/'
    },
    {
      name: 'About Us',
      path: '/about'
    },

  ]
  return (
    <div className='relative z-50'>

      <Helmet>
        <title>DataEquity | About Us</title>
        <meta name="description" content="DataEquity | About Us" />
        <meta name="keywords" content="API Design, API Mocking" />
        <meta property="og:title" content="DataEquity | About Us" />
        <meta property="og:description" content="DataEquity | About Us" />
      </Helmet>

      <div ref={ref} >
        <Breadcrumb title='About Us' routes={routes} />
        <div ref={ref} className={`flex justify-center items-baseline py-[80px] sm:py-8 relative overflow-hidden`}>
          <div className='w-[1225px] flex flex-col justify-start items-center gap- sm:w-auto sm:px-6'>
            <div className='flex gap-[40px] flex-col w-full'>
            <div className='flex justify-between items-start w-full sm:flex-col sm:items-center sm:gap-6'>
              <h2 className='font-bold text-black2 font-poppins text-[48px] leading-[57.6px] capitalize sm:text-[32px] sm:text-center sm:leading-9'>
                  Who We Are and <br />
                  <span className='italic'>What We Stand For</span>
              </h2>
              <img src='/dnjn.png' alt="pin" className='object-cover w-full h-[274px] rounded-xl sm:flex hidden' />
              <div className='flex flex-col gap-[22px] w-[661px] sm:w-auto'>
                  <p className="text-base font-inter font-normal text-gray2">
                      Founded in early 2023, <span className='font-semibold italic'>Data Equity</span> is a specialized Data Discovery & Valuation Platform combined with an information commercialization marketplace designed to curate and expose information artefacts using a set of compliant APIs.
                  </p>
                  <p className="text-base font-inter font-normal text-gray2">
                      This first-of-its-kind Information <span className='font-semibold italic'>Asset Valuation Framework (IAVF)</span> and information monetization platform will empower business enterprises to unlock the untapped potential of their data (intangible assets) and convert it to real equity value on their balance sheet.
                  </p>
                  <p className="text-base font-inter font-normal text-gray2">
                      At Data Equity, we also specialize in Data center management, API design and mocking, providing robust tools to help businesses starting their API journey to create, test, and deploy APIs efficiently. Our platform supports seamless integration and customization, ensuring that your APIs meet the highest standards of performance and reliability.
                  </p>
                  <p className="text-base font-inter font-normal text-gray2">
                      By leveraging our powerful API design and mocking capabilities, businesses can streamline their development processes, reduce time-to-market, and enhance the overall quality of their digital products and services.
                  </p>
              </div>
          </div>
              <img src='/dnjn.png' alt="pin" className='object-cover w-full h-[429.09px] rounded-xl sm:hidden' />

            </div>


            <div className='flex gap-[40px] flex-col w-full mt-[100px]'>
              <div className='flex gap-[16px] flex-col w-full justify-center items-center'>
                <h2 className='font-bold gradient-text font-poppins text-[48px] leading-[57.6px] capitalize text-center sm:text-[28px]'>
                  Our Mission to Build a <br />
                  <span className='italic'>Better Future</span>
                </h2>
                <p className="text-base font-inter font-normal text-gray2 text-center w-[534px] sm:w-auto" >
                  Unlock the Hidden Potential and Equity of Your Data for Greater Insights and Opportunities.
                </p>
              </div>

              <div className='flex justify-between items-center gap-[43px]'>
                <div className='flex flex-col gap-6 z-10'>
                  <div className='sm:flex sm:bg-white sm:flex-col sm:rounded-xl sm:p-4 sm:shadow-lg sm:gap-4'>
                    <img src='/njn.png' alt="pin" className='object-cover h-[295px] rounded-xl sm:flex hidden' />
                    <h3 className='font-poppins font-semibold text-[24px] text-black2'>Our Vision</h3>
                    <p className="text-base font-inter font-normal text-gray2 sm:-mt-3">
                      We use Generative AI to an Open Data Discovery platform to connect to your enterprise data and provide data valuation, API recommendations and facilitate data monetization through your APIs published on our API Marketplace.  </p>
                  </div>
                  <div className='sm:flex sm:bg-white sm:flex-col sm:rounded-xl sm:p-4 sm:shadow-lg sm:gap-4'>
                    <img src='/mvkfm.png' alt="pin" className='object-cover h-[295px] rounded-xl sm:flex hidden' />
                    <h3 className='font-poppins font-semibold text-[24px] text-black2'>Our Culture</h3>
                    <p className="text-base font-inter font-normal text-gray2 sm:-mt-3">
                      We place a strong emphasis on innovation and creativity. We encourage employees to think outside the box, experiment with new ideas, and push the boundaries of what AI can achieve. Creativity is valued not only in research and development but also in problem-solving and finding novel solutions for clients.
                    </p>
                  </div>

                </div>
                <img src='/vision.png' alt="pin" className='object-cover w-[570px] rounded-xl sm:hidden' />
              </div>
            </div>

            <Gradient
              className={
                "w-[159px] h-[159px] rounded-full bg-gradientBlue blur-[150px] top-[900px] left-10 sm:flex hidden"
              }
            />

            <Gradient
              className={
                "w-[159px] h-[159px] rounded-full bg-orange blur-[150px] top-[1500px] left-10 sm:flex hidden"
              }
            />

            <Gradient
              className={
                "w-[159px] h-[159px] rounded-full bg-orange blur-[150px] bottom-0 right-10 sm:flex hidden"
              }
            />

          </div>
        </div>

        <div className='flex overflow-hidden justify-center items-center py-[100px] relative sm:py-10 sm:px-6'>
          <div className='flex gap-[40px] flex-col w-[1225px] sm:w-auto sm:gap-5'>
            <div className='flex gap-[16px] flex-col w-full justify-center items-center relative z-20'>
              <h2 className='font-bold gradient-text font-poppins text-[48px] leading-[57.6px] capitalize text-center sm:text-[28px]'>
                Meet Our Visionary Founders
              </h2>
              <p className="text-base font-inter font-normal text-gray2 text-center w-[623px] sm:w-auto" >
                Discover the story behind our visionary founders and their commitment to driving innovation and success in the industry.
              </p>
            </div>

            <div className='relative z-20 overflow-hidden flex justify-between items-center bg-white shadow-lg p-[36px] sm:p-5 sm:flex-col sm:gap-6 rounded-[16px] gap-[101px]'>
              <img src='/sandeep.png' alt='sandeep' className='w-[391px] h-[391px] rounded-xl object-cover z-10 sm:h-[295px] object-top' />
              <div className='gap-4 flex flex-col'>
                <h3 className='font-poppins font-bold text-[32px] text-black2 sm:text-center sm:text-[20px]'>Sandeep Sundaram</h3>
                <p className='hidden sm:block sm:text-[18px] font-semibold font-inter text-center text-gray2 w-full sm:-mt-3'>Co-Founder</p>
                <p className="text-base font-inter font-normal text-gray2 sm:hidden">
                  Sandeep Sundaram is an experienced business leader focussed on digital technologies with a strong domain and technology experience spanning consulting, architecture, systems integration, pre-sales and execution spanning 20 years. Prior to founding Data Equity, he had founded Perse and also operated as the Chief Technology Officer for DigitalAPICraft and established the API-led approach that enabled retail, banking, insurance, telecom, and media companies to offer delightful customer experience, transform business processes and boost operational efficiency.
                </p>
              </div>
              <Gradient
                className={
                  "w-[159px] h-[159px] rounded-full bg-gradientBlue bottom-[-50px] blur-[120px] left-[-50px] sm:bottom-[-180px] sm:left-10"
                }
              />
            </div>
            <div className='relative z-20 overflow-hidden flex justify-between items-center bg-white shadow-lg p-[36px] rounded-[16px] gap-[101px] sm:p-5 sm:flex-col-reverse sm:gap-6'>
              <div className='gap-4 flex flex-col'>
                <h3 className='font-poppins font-bold text-[32px] text-black2 sm:text-center sm:text-[20px]'>Harish Gopal</h3>
                <p className='hidden sm:block sm:text-[18px] font-semibold font-inter text-center text-gray2 w-full sm:-mt-3'>Co-Founder</p>
                <p className="text-base font-inter font-normal text-gray2 sm:hidden">
                  Harish Gopal brings onboard 22 years of experience in an eclectic mix of executive leadership roles over the years. He has been actively involved in product strategy and consulting for complex IT programmes in banking, utilities and other digital technologies. He was the Chief Executive Officer for Enzen Spain and head of Transaction Advisory Services for Enzen Global Limited, UK - Winning, leading and delivering transactions worth over 30 Bn USD globally for blue chip investment banks and infrastructure funds including Macquarie Infrastructure and Real Assets UK, Deutsche Bank Spain, UBS UK, etc.
                </p>
              </div>
              <img src='/gopal.png' alt='gopal' className='w-[391px] h-[391px] rounded-xl object-cover bg-white z-10 sm:h-[295px] object-top' />
              <Gradient
                className={
                  "w-[159px] h-[159px] rounded-full bg-orange blur-[150px] top-[0px] right-[0px] sm:right-10 sm:top-[-50px]"
                }
              />
            </div>

          </div>

          <Gradient
            className={
              "w-[159px] h-[159px] rounded-full bg-orange blur-[180px] bottom-[120px] right-[100px]  sm:hidden"
            }
          />
          <Gradient
            className={
              "w-[159px] h-[159px] rounded-full bg-orange blur-[200px] bottom-[200px] left-[180px] sm:hidden"
            }
          />
          <Gradient
            className={
              "w-[159px] h-[159px] rounded-full bg-gradientBlue blur-[120px] top-[40px] left-[40px] sm:top-[-100px]"
            }
          />
          <Gradient
            className={
              "w-[159px] h-[159px] rounded-full bg-gradientBlue blur-[180px] top-[600px] right-[150px] sm:top-[850px]"
            }
          />
        </div>

        <div className='sm:flex overflow-hidden justify-center items-center py-[100px] relative sm:py-10 sm:px-6 hidden '>

          <div className='relative z-20 overflow-hidden flex justify-between items-center bg-white shadow-lg p-[36px] sm:p-5 sm:flex-col sm:gap-6 rounded-[16px] gap-[101px]'>
            <img src='/jhon.png' alt='jhon' className='w-[391px] h-[391px] rounded-xl object-cover z-10 sm:h-[295px] object-top filter grayscale' />
            <div className='gap-4 flex flex-col'>
              <h3 className='font-poppins font-bold text-[32px] text-black2 sm:text-left sm:text-[20px]'>John Doe</h3>
              <p className='hidden sm:block sm:text-[18px] font-semibold font-inter text-left text-gray2 w-full sm:-mt-3'>Advisors</p>
              <svg width="34" height="28" viewBox="0 0 34 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.3841 21.514C15.3841 19.754 14.9241 18.276 14.0041 17.08C13.3521 16.256 12.4674 15.7147 11.3501 15.456C10.2501 15.2 9.21008 15.182 8.27008 15.4C7.95008 13.5 8.47008 11.488 9.79008 9.356C11.1127 7.22533 12.8181 5.62333 14.9061 4.55L11.7441 0C10.1441 0.792 8.62408 1.796 7.22408 3.01C5.80408 4.224 4.54408 5.62 3.42408 7.198C2.30408 8.776 1.46408 10.558 0.924076 12.578C0.384076 14.598 0.234076 16.658 0.492076 18.778C0.828076 21.578 1.73208 23.818 3.20408 25.478C4.67474 27.1593 6.50674 28 8.70008 28C10.6301 28 12.2321 27.42 13.5001 26.244C14.7547 25.092 15.3814 23.5133 15.3801 21.508L15.3841 21.514ZM33.6321 21.514C33.6321 19.754 33.1721 18.276 32.2521 17.08C31.5987 16.24 30.7141 15.6953 29.5981 15.446C28.4781 15.1993 27.4514 15.1847 26.5181 15.402C26.1981 13.522 26.6981 11.502 28.0221 9.362C29.3421 7.242 31.0461 5.642 33.1341 4.562L29.9801 0C28.3787 0.792 26.8721 1.79533 25.4601 3.01C24.0273 4.24214 22.7547 5.64912 21.6721 7.198C20.5601 8.778 19.7321 10.558 19.1921 12.578C18.6435 14.5964 18.4961 16.7028 18.7581 18.778C19.0901 21.578 19.9901 23.818 21.4581 25.478C22.9247 27.1473 24.7534 27.982 26.9441 27.982C28.8774 27.9847 30.4787 27.4 31.7481 26.228C33.0027 25.076 33.6307 23.4973 33.6321 21.492V21.514Z" fill="#5981F5" />
              </svg>
              <p className="text-base font-inter font-normal text-gray2">
                John Doe is a financial advisor with over 10 years of experience in wealth management and retirement planning. He specializes in crafting personalized financial strategies to help clients achieve their long-term goals. John holds a Certified Financial Planner (CFP) designation and is passionate about empowering individuals to make informed financial decisions.
              </p>
            </div>

          </div>
          <Gradient
            className={
              "w-[159px] h-[159px] rounded-full bg-orange blur-[150px] top-[0px] right-[60px] "
            }
          />
        </div>

        <div className='flex justify-center items-center py-[100px] sm:py-8'>
          <div className='w-[1225px] flex flex-col justify-center items-center gap-12 sm:w-auto'>
            <h2 className='font-bold gradient-text font-poppins text-[48px] leading-[57.6px] capitalize text-center sm:text-[28px] sm:px-6'>
              Explore Our Global {" "}
              <span className='italic'>Locations <br className='sm:hidden' /> and Reach</span>
            </h2>
            <div className='flex justify-center items-center gap-8 -mt-6'>
              <div className='flex justify-center items-center gap-3'>
                <span className='flex justify-center items-center w-5 h-5 border rounded-full border-gray3'>
                  <span className='w-3 h-3 rounded-full bg-gradientBlue'></span>
                </span>
                <p className='font-inter text-[18px] font-semibold text-gray2'>SLOUGH, UK</p>
              </div>
              <div className='flex justify-center items-center gap-3'>
                <span className='flex justify-center items-center w-5 h-5 border rounded-full border-gray3'>
                  <span className='w-3 h-3 rounded-full bg-[#D26CE8]'></span>
                </span>
                <p className='font-inter text-[18px] font-semibold text-gray2'>DUBAI, UAE</p>
              </div>
            </div>
            <img src='/map.png' alt="pin" className='w-full h-[582px] object-contain sm:h-[285px] sm:object-cover' />
            <div className='w-[1016px] mt-20 -mb-[470px] sm:w-auto sm:mt-0 sm:px-6'>
              <ContactForm about={true} />
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default About
