import CookieConsent from "react-cookie-consent";

function Cookies() {

    return (
        <CookieConsent
        acceptOnScroll={true}
        acceptOnScrollPercentage={30}
        buttonClasses="btn btn-primary"
        containerClasses="alert alert-warning col-lg-12"
        contentClasses="text-capitalize"
        cookieName="dataequity"
        style={{
            background: "linear-gradient(90deg, #2B2D3E 0%, #69709A 100%)",
          }}
        >
         This website uses cookies to enhance the user experience.{" "}
        </CookieConsent>
    )
}

export default Cookies;