import React from 'react'

function Loader() {
    return (
        <div class="loader-container flex justify-center items-center h-[40vh]">
            <div class="loader loader2">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default Loader
