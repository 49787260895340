import React from 'react'

function Gradient({ className }) {
  return (
    <div className={`absolute z-0 ${className}`}>
      
    </div>
  )
}

export default Gradient
