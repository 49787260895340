import React, { useEffect, useState } from 'react'
import Gradient from '../Gradient'
import Button from '../Button'
import Table from './Table'
import { useNavigate } from "react-router-dom";

function PackagesCompared() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 782);
    const navigate = useNavigate();

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth < 782);
      };
  
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    return (
        <div className='flex flex-col gap-14 sm:gap-8'>
            <div className='flex gap-[16px] flex-col w-full justify-center items-center'>
                <h2 className='font-bold gradient-text font-poppins text-[48px] sm:text-[28px] leading-[57.6px] capitalize text-center w-[906px] sm:w-auto'>
                    Compare plans
                </h2>
                <p className="text-base font-inter font-normal text-gray2 text-center w-[668.77px] sm:w-auto" >
                    Choose the plan that fits your business needs. Our flexible pricing ensures you can start unlocking the untapped potential of your data today.
                </p>
            </div>

            <div className='relative w-full'>
                <div className='flex justify-start items-stretch sm:items-start  gap-2 w-full relative z-10 sm:flex-col'>
                    <div className='flex-1 sm:hidden'></div>

                    <div className='rounded-[16px] w-[321.4px] sm:w-full flex flex-col justify-start items-start relative overflow-hidden py-5 px-6'>
                        <h2 className='font-semibold font-inter text-[24px] text-black2'>Basic Plan</h2>
                        <p className='font-inter font-medium text-base text-left text-black2 '>For small businesses getting started</p>
                        <p className='font-poppins font-medium text-base text-center text-black2 my-[50px]'><span className='text-[36px] font-semibold'>$0 </span>/ month</p>
                        <span className='w-full'>
                            <Button
                                text={"Get Started"}
                                light={true}
                                classes="!font-semibold w-full !text-gradientBlue hover:!text-white !text-base !px-6 !py-4"
                                onClick={() => window.open('https://studio.dataequity.io', '_blank')}
                            />
                        </span>
                        <Gradient
                            className={
                                "w-[159px] h-[159px] rounded-full bg-gradientBlue top-[-100px] blur-[120px] left-[-80px]"
                            }
                        />
                        <Gradient
                            className={
                                "w-[159px] h-[159px] rounded-full bg-gradientBlue bottom-[-100px] blur-[120px] right-[-100px]"
                            }
                        />
                    </div>
                      {/* {isMobile &&  <Table basic={true} team={false} enterprise={false}/>}
                    <div className='rounded-[16px] w-[321.4px] sm:w-full flex flex-col justify-start bg-darkBlue items-start relative overflow-hidden py-5 px-6'>
                        <h2 className='font-semibold font-inter text-[24px] text-white z-10'>Team Plan</h2>
                        <p className='font-inter font-medium text-base text-left text-white  z-10'>For growing businesses scaling their data.</p>
                        <p className='font-poppins font-medium text-base text-center text-white z-10 my-[50px]'><span className='text-[36px] font-semibold'>$49 </span>/ month</p>
                        <span className='w-full'>
                            <Button
                                text={"Contact Sales"}
                                classes="!font-semibold w-full !text-base !px-6 !py-4"
                                onClick={() => navigate('/contact')}
                            />
                        </span>
                        <Gradient
                            className={
                                "w-[159px] h-[159px] rounded-full bg-gradientBlue bottom-[-50px] blur-[90px] left-[-50px]"
                            }
                        />
                        <Gradient
                            className={
                                "w-[159px] h-[159px] rounded-full bg-orange top-[-50px] blur-[90px] right-[-50px]"
                            }
                        />

                    </div> */}
                       {isMobile && <Table basic={false} team={true} enterprise={false}  />}
                    <div className='rounded-[16px] w-[321.4px] sm:w-full flex flex-col justify-start items-start relative overflow-hidden py-5 px-6'>
                        <h2 className='font-semibold font-inter text-[24px] text-black2'>Enterprise Plan</h2>
                        <p className='font-inter font-medium text-base text-left text-black2'>For large businesses with custom needs.</p>
                        <p className='font-poppins font-medium text-base text-center text-black2 my-[50px]'><span className='text-[36px] font-semibold'>Let's Talk </span></p>
                        <span className='w-full'>
                            <Button
                                text={"Contact Sales"}
                                light={true}
                                classes="!font-semibold w-full !text-gradientBlue hover:!text-white !text-base !px-6 !py-4"
                                onClick={() => navigate('/contact')}
                            />
                        </span>
                        <Gradient
                            className={
                                "w-[159px] h-[159px] rounded-full bg-orange bottom-[-90px] blur-[120px] left-[-90px]"
                            }
                        />
                        <Gradient
                            className={
                                "w-[159px] h-[159px] rounded-full bg-orange top-[-90px] blur-[120px] right-[-90px]"
                            }
                        />
                    </div>
                      {isMobile &&  <Table basic={false} team={false} enterprise={true} />}
                </div>
                <div className='flex sm:hidden w-full'>
                    <Table />
                </div>
                <div className='absolute sm:hidden w-[328.4px] border-2 border-gradientBlue rounded-[16px] top-[-4px] right-[326px] h-full z-0'>
                </div>
            </div>

        </div>
    )
}

export default PackagesCompared
