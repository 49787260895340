import React from 'react'
import Breadcrumb from '../components/Breadcrumb';
import { useInView } from 'react-intersection-observer';
import { Helmet } from 'react-helmet';

function PrivacyPolicy() {

    const { ref } = useInView({
        triggerOnce: true,
        threshold: 0,
    });

    const routes = [
        {
            name: 'Home',
            path: '/'
        },
        {
            name: 'Privacy Policy',
            path: '/privacy-policy'
        },
    ]

    const RenderSection = ({ title, subHeading, content, list }) => (
        <>
        <Helmet>
            <title>DataEquity | Privacy Policy</title>
            <meta name="description" content="DataEquity Privacy Policy Page" />
            <meta name="keywords" content="Privacy Policy" />
            <meta name="author" content="DataEquity" />
            <meta property="og:title" content="DataEquity | Privacy Policy" />
            <meta property="og:description" content="DataEquity Privacy Policy Page" />
        </Helmet>

            {title && <h2 className='font-semibold text-[24px] font-poppins gradient-text sm:text-[20px] mt-2'>{title}</h2>}
            {subHeading && <h3 className='font-semibold text-[18px] font-poppins gradient-text sm:text-[18px] -mb-3'>{subHeading}</h3>}
            {content?.split('\n').map((item, index) => (
                <p className="text-base font-inter font-normal text-gray2">
                    {item}
                </p>
            ))}
            {list?.split('\n').map((item, index) => (
                item.length > 2 ?
                    <div className="flex justify-start items-start  gap-3">
                        <img alt="pin" src={"/Frame.png"} className="w-[24px] h-[24px]" />
                        <p className="text-base font-medium font-inter text-gray2">
                            {item}
                        </p>
                    </div>
                    :
                    <></>
            ))}
        </>

    )

    return (
        <div ref={ref} >
            <Breadcrumb title='Privacy Policy' routes={routes} />
            <div className='flex relative overflow-hidden justify-center items-center flex-col py-[100px] sm:py-8'>
                <div className='w-[1225px] sm:w-auto sm:px-6 relative z-10 flex flex-col gap-2 sm:gap-2'>
                    <h2 className='font-semibold text-[48px] font-poppins gradient-text sm:text-[32px]'>Privacy Policy</h2>
                    <p className='font-inter font-medium text-[18px] text-gray2'>Last Updated: November 06, 2023</p>

                    {/* <h2 className='font-semibold text-[24px] font-poppins gradient-text sm:text-[20px] mt-2'>AGREEMENT TO OUR LEGAL TERMS</h2> */}


                    {RenderSection({
                        title: null,
                        subHeading: null,
                        content: `     
                              This privacy statement, pertaining to Data Equity Ltd ('we,' 'us,' or 'our'), outlines the reasons and methods by which we may collect, retain, employ, and/or disclose ('process') your information when you engage with our services ('Services'). These interactions include:
                        `,
                        list: `
                            Visiting our websites at https://dataequity.io, https://studio.dataequity.io, https://docs.dataequity.io, https://recommender.dataequity.io, or any of our websites that reference this privacy statement.
                            Engaging with us in various other capacities, such as sales, marketing, or events. `
                    })}

                    {RenderSection({
                        title: null,
                        subHeading: null,
                        content: 'Do you have any questions or concerns? Reviewing this privacy statement will provide you with insights into your privacy rights and choices. If you do not agree with our policies and practices, kindly refrain from using our Services. For any remaining questions or concerns, please do not hesitate to reach out to us at support@dataequity.io.',
                        list: null,
                    })}
                    {RenderSection({
                        title: `1. WHAT INFORMATION DO WE COLLECT?`,
                        subHeading: null,
                        content: `
                            TL;DR: Personal information voluntarily shared with us.
                            This information is willingly provided by you when you register for our Services, express an interest in acquiring details about our products and Services, participate in activities on our platform, or contact us through various means.
                            The specific personal information we collect is determined by the nature of your interactions with our Services, the choices you make, and the specific products and features you utilize. This may include:
                        `,
                        list: `
                            email address
                            names
                            job titles`,
                    })}
                    {RenderSection({
                        title: null,
                        subHeading: null,
                        content: `
                            Sensitive Information. We do not process sensitive information.
                            Social Media Login Data. You may have the option to register using your existing social media account details, such as Gmail, GitHub, or other social media accounts.
                            It's crucial that all personal information provided to us is accurate, complete, and up to date. Should any changes occur in your personal information, please inform us promptly.`,
                        list: null,
                    })}
                    {RenderSection({
                        title: null,
                        subHeading: `Information automatically collected.`,
                        content: `
                            TL;DR: When you visit our Services, certain information, like your Internet Protocol (IP) address, browser and device characteristics, is collected automatically.
                            We automatically gather specific information as you access, use, or navigate the Services. While this information does not disclose your precise identity, such as your name or contact details, it encompasses data related to your device and its usage. This includes your IP address, browser and device specifications, operating system, language preferences, referring URLs, device name, location, country, data on how and when you engage with our Services, and other technical details. This information is primarily used to maintain the security and functionality of our Services, and for our internal analytics and reporting purposes.
                            Log and usage data encompasses service-related, diagnostic, usage, and performance information. Our servers automatically gather this data when you access or use our Services and record it in log files. Depending on your interactions with our Services, this log data may comprise your IP address, device details, browser type and settings, as well as information about your activity on the platform, including date and time stamps associated with your usage, the pages and files you view, searches, and other actions you take (such as which features you employ). Device event information, such as system activity, error reports (sometimes referred to as 'crash dumps'), and hardware settings, may also be included.
`,
                        list: null,
                    })}
                    {RenderSection({
                        title: `2. HOW DO WE MANAGE YOUR INFORMATION?`,
                        subHeading: null,
                        content: `
                            TL;DR: We handle your information to offer, enhance, and oversee our Services, to engage with you, for security and fraud prevention, and to adhere to legal requirements. Additionally, we might process your information for different purposes upon obtaining your consent.
                            We process your personal information for various reasons, determined by the nature of your interactions with our Services, including:`,
                        list: `
                            Facilitating account creation, authentication, and general user account management. Your information may be processed to enable you to create and access your account while ensuring its smooth operation.
                            Safeguarding an individual's essential interests. We may process your information when it's necessary to protect an individual's crucial interests, such as preventing harm.`,
                    })}
                    {RenderSection({
                        title: `3. WHAT ARE OUR LEGAL GROUNDS FOR PROCESSING YOUR INFORMATION?`,
                        subHeading: null,
                        content: `
                            TL;DR: We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e. legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfil our contractual obligations, to protect your rights, or to fulfil our legitimate business interests.
                            If you are situated in the EU or UK, the following section is relevant to you:
                            The General Data Protection Regulation (GDPR) and UK GDPR necessitate us to clarify the valid legal bases on which we rely to process your personal information. Consequently, we may rely on the subsequent legal bases for processing your personal information:
                            `,
                        list: `
                            Consent: We may process your information if you have granted us explicit permission (i.e., consent) to use your personal data for a specific purpose. You have the option to withdraw your consent at any time.
                            Legal Obligations: We may process your information when we believe it is necessary to fulfill our legal obligations, such as cooperating with law enforcement agencies or regulatory bodies, safeguarding or defending our legal rights, or providing your information as evidence in legal proceedings in which we are involved.
                            Vital Interests: Your information may be processed if we determine it is crucial to protect your vital interests or those of a third party, particularly in situations involving potential threats to an individual's safety.`,
                    })}


                    {/* you can copy this element use this for multiple sections */}
                    {RenderSection({
                        title: null, 
                        subHeading: null, 
                        content: `In legal terminology, we are generally considered the 'data controller' under European data protection regulations for the personal information described in this privacy notice. This is because we determine the means and/or purposes of the data processing we carry out. Please note that this privacy notice doesn't pertain to the personal information we process as a 'data processor' on behalf of our customers. In such cases, the customer we provide services to, with whom we have established a data processing agreement, acts as the 'data controller' responsible for your personal data. We, in turn, process your information as per their instructions. If you wish to learn more about the privacy practices of our customers, you should consult their privacy policies and direct any inquiries you may have to them.
                        If you are residing in Canada, this section pertains to you.
                        In certain extraordinary circumstances, we may have legal authorization under the relevant laws to process your data without requiring your explicit consent. These circumstances include, but are not limited to, the following:`, 
                        list: `When the data collection is evidently in the best interests of an individual, and obtaining consent within a reasonable timeframe is unfeasible.
                                For the purpose of investigations and the prevention of fraud.
                                For business transactions, provided that specific conditions are met.
                                If the information is contained in a witness statement, and its collection is essential for the evaluation, processing, or resolution of an insurance claim.
                                For the identification of injured, unwell, or deceased individuals and for communication with their next of kin.
                                When we have valid reasons to believe that an individual has been, is, or may become a victim of financial abuse.
                                When it is reasonable to assume that collecting and using the information with consent would jeopardize the availability or accuracy of the data, and the collection is justified for the purposes related to investigating a breach of an agreement or a violation of Canadian or provincial laws.
                                If disclosure is mandated to comply with a subpoena, warrant, court order, or court rules related to record production.
                                If the information was generated by an individual during the course of their employment, business, or profession, and the collection aligns with the original purposes for which the information was created.
                                If the data collection is exclusively for journalistic, artistic, or literary purposes.
                                When the information is publicly accessible and is specified by regulatory guidelines.`, 
                    })}
                    {RenderSection({
                        title: `4. WHEN AND WITH WHOM DO WE DISCLOSE YOUR PERSONAL INFORMATION?`,
                        subHeading: null,
                        content: `
                            TL;DR: We might disclose information under the circumstances outlined in this section and/or with the third parties mentioned below.
                            Your personal information may be shared in the following instances:
                            Business Transfers We may distribute or transmit your data in relation to, or while engaged in discussions concerning, any merger, sale of corporate assets, financing, or the acquisition of our business, either wholly or partially, by another company.
                            `,
                        list: null,
                    })}
                    {RenderSection({
                        title: `5. ARE COOKIES AND OTHER TRACKING TECHNOLOGIES UTILIZED?`,
                        subHeading: null,
                        content: `
                           TL;DR: We might employ cookies and other tracking technologies for the purpose of gathering and storing your data.
                            These cookies collect information that is used either in aggregate form to help us understand how our Website is being used or how effective our marketing campaigns are, or to help us customize our Website for you.
                            Name:_ga
                            Purpose:Records a particular ID used to come up with data about website usage by the user
                            Provider:.dataequity.io
                            Service:Google Analytics View Service Privacy Policy
                            Country:United States
                            Type:http_cookie
                            Expires in:1 year 1 month 4 days

                            Name:_ga_#
                            Purpose:Used to distinguish individual users by means of designation of a randomly generated number as client identifier, which allows calculation of visits and sessions
                            Provider:.dataequity.io
                            Service:Google Analytics View Service Privacy Policy
                            Country:United States
                            Type:http_cookie
                            Expires in:1 year 1 month 4 days
                            In addition we use commercial SaaS tools to securely and anonymously track user interactions on the website. These tools include:
                            `,
                        list: null,
                    })}
                    {RenderSection({
                        title: `6. HOW DO WE MANAGE YOUR SOCIAL MEDIA LOGINS?`,
                        subHeading: null,
                        content: `
                           TL;DR: If you opt to sign up or log in to our Services via a social media account, we may gain access to specific details about you.
                            Our Services grant you the option to register and log in using your third-party social media account credentials, such as your Gmail or GitHub logins. If you choose this method, we will receive certain profile information about you from your social media provider. The exact information may vary depending on the particular social media provider, but typically includes your name, email address, profile picture, and any other information you have made publicly accessible on that social media platform. The information we obtain will be used solely for the purposes outlined in this privacy notice or those clearly communicated to you within the relevant Services. It's important to note that we have no authority over and are not accountable for how your third-party social media provider utilizes your personal information. We recommend reviewing their privacy notice to comprehend how they collect, utilize, and share your personal information, and how you can set your privacy preferences on their websites and applications.`,
                        list: null,
                    })}
                    {RenderSection({
                        title: `7. HOW LONG IS YOUR INFORMATION RETAINED?`,
                        subHeading: null,
                        content: `
                           TL;DR: We retain your information as needed to fulfill the purposes outlined in this privacy notice, unless otherwise mandated by the law.
                            We will exclusively retain your personal information for the duration necessary to achieve the objectives specified in this privacy notice, except when an extended retention period is imposed or permitted by law (e.g., for tax, accounting, or legal requirements). None of the purposes outlined in this notice entail retaining your personal information beyond the period when users maintain an account with us.
                            In cases where we no longer possess a legitimate business necessity to process your personal information, we will either delete or anonymize the data. If deletion is not immediately feasible (e.g., due to your information being stored in backup archives), we will securely safeguard and segregate your personal information to prevent any further processing until such deletion becomes feasible.
                            `,
                        list: null,
                    })}
                    {RenderSection({
                        title: `8. HOW DO WE ENSURE YOUR INFORMATION'S SECURITY?`,
                        subHeading: null,
                        content: `
                           TL;DR: We employ a combination of organizational and technical security measures to safeguard your personal data.
                            We have implemented suitable and rational technical and organizational security measures to safeguard the integrity of the personal information we handle. Nevertheless, while we endeavor to protect your information through these measures, it's crucial to acknowledge that no electronic data transmission or storage technology can guarantee absolute security. Therefore, we cannot assure or warrant that malicious actors, cybercriminals, or unauthorized third parties will be unable to bypass our security measures and illicitly gather, access, steal, or alter your information. While we will make every effort to protect your personal information, the transmission of such data to and from our Services is undertaken at your own risk. We recommend accessing the Services within a secure environment.
                            `,
                        list: null,
                    })}
                    {RenderSection({
                        title: `9. DO WE GATHER INFORMATION FROM MINORS?`,
                        subHeading: null,
                        content: `
                           TL;DR: We do not intentionally collect data from or market to individuals under the age of 18.
                            We do not intentionally request information from or promote our services to individuals under the age of 18. By utilizing our Services, you affirm that you are at least 18 years old or that you are the legal parent or guardian of a minor who is using the Services and consent to the minor's use. In the event that we discover that we have obtained personal information from users under the age of 18, we will deactivate the account and take appropriate actions to expeditiously remove such data from our records. If you become aware of any information that may have been collected from individuals under the age of 18, please contact us at sandeep.sundaram@dataequity.io.
                            `,
                        list: null,
                    })}
                    {RenderSection({
                        title: `10. WHAT ARE YOUR PRIVACY RIGHTS?`,
                        subHeading: null,
                        content: `
                           TL;DR: In certain regions like the EEA, UK, Switzerland, and Canada, you have rights regarding your personal information, such as access, rectification, erasure, and more. You can also modify or terminate your account anytime.
                            In specific regions, including the EEA, UK, Switzerland, and Canada, you possess certain rights under relevant data protection regulations. These rights encompass the ability to (i) request access to and receive a copy of your personal information, (ii) seek corrections or deletion, (iii) limit the processing of your personal information, (iv) when applicable, request data portability, and (v) avoid automated decision-making. Under particular circumstances, you may also have the right to object to the processing of your personal information.
                            We will assess and respond to your requests in accordance with the applicable data protection laws.
                            If you are situated in the EEA or UK and believe that your personal information is being processed unlawfully, you have the option to file a complaint with your Member State data protection authority or the UK data protection authority.
                            For individuals in Switzerland, you can get in touch with the Federal Data Protection and Information Commissioner.
                            Withdrawal of consent: If we rely on your consent for processing your personal information, which may involve express or implied consent based on applicable laws, you have the right to revoke your consent at any time. You can do so by contacting us.
                            However, please be aware that withdrawing consent will not impact the legality of the processing that occurred before the withdrawal. It also won't affect the processing of your personal information that is based on legal grounds other than consent, as permitted by applicable law.
                            If, at any point, you wish to review, modify, or terminate the information in your account, you can reach out to us using the provided contact information. Upon your request to terminate your account, we will deactivate or erase your account and information from our active databases. Nevertheless, we may retain certain data in our records for fraud prevention, issue resolution, assistance with investigations, enforcement of our legal terms, and adherence to applicable legal obligations.
                            For any queries or feedback regarding your privacy rights, please email us at support@dataequity.io.
                            `,
                        list: null,
                    })}
                    {RenderSection({
                        title: `11. DO WE REVISE THIS NOTICE?`,
                        subHeading: null,
                        content: `
                           TL;DR: Yes, we revise this notice as needed to remain in compliance with applicable laws.
                            We may periodically revise this privacy notice. The modified version will be identified by a revised 'Revised' date and will become effective upon its availability. If substantial changes are made to this privacy notice, we may inform you through conspicuous notification of such alterations or direct notifications to you. We recommend reviewing this privacy notice regularly to stay informed about how we safeguard your information.
                            `,
                        list: null,
                    })}
                   {RenderSection({
                        title: `12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?`,
                        subHeading: null,
                        content: `
                            If you have questions or comments about this notice, you may contact our Data Protection Officer (DPO), Sandeep Sundaram, by email at sandeep.sundaram@dataequity.io, or contact us by post at:
                            Data Equity Ltd
                            Sandeep Sundaram
                            18 Arundel Court
                            Slough, England SL3 7NP
                            United Kingdom
                            If you are a resident in the United Kingdom, we are the 'data controller' of your personal information. We have appointed Sandeep Sundaram to be our representative in the UK. You can contact them directly regarding our processing of your information, by email at sandeep.sundaram@dataequity.io, or by post to:
                            18 Arundel Court
                            Slough, England SL3 7NP
                            United Kingdom

                            If you have any further questions or concerns about our privacy policy, please contact us at support@dataequity.io


            `,
                        list: null,
                    })}

                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy
