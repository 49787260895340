import React from 'react'
import Button from '../Button'
import Gradient from '../Gradient'
import { useNavigate } from "react-router-dom";

function Packages() {
  const navigate = useNavigate();
  return (
  <div className='flex flex-col gap-14 sm:gap-8'>
    <div className='flex gap-[16px] flex-col w-full justify-center items-center'>
        <h2 className='font-bold gradient-text font-poppins text-[48px] leading-[57.6px] sm:text-[32px] capitalize text-center sm:w-auto w-[906px]'>
            Unlock the Value of Your Data with Flexible Pricing Plans
        </h2>
        <p className="text-base font-inter font-normal text-gray2 text-center w-[607px] sm:w-auto" >
            Choose the plan that fits your business needs. Our flexible pricing ensures you can start unlocking the untapped potential of your data today.
        </p>
    </div>
    <div className='flex gap-6 sm:gap-5 justify-center sm:flex-col items-start w-full'>
        
        <div className='flex relative flex-1 sm:w-full rounded-[16px] overflow-hidden justify-center items-center flex-col gap-[68px] pb-6 shadow-xl'>

            <div className='rounded-[16px] flex flex-col justify-center items-center gap-6 relative overflow-hidden w-full  py-8 px-6 h-[184px]'>
                <h2 className='font-semibold font-inter text-[24px] text-black2'>Basic Plan</h2>
                <p className='font-inter font-medium text-base text-center text-black2 -mt-3'>For small businesses getting started</p>
                <p className='font-poppins font-medium text-base text-center text-black2'><span className='text-[36px] font-semibold'>$0 </span>/ month</p>
                <Gradient
                    className={
                        "w-[159px] h-[159px] rounded-full bg-gradientBlue top-[-70px] blur-[120px] left-[-70px]"
                    }
                />
                <Gradient
                    className={
                        "w-[159px] h-[159px] rounded-full bg-gradientBlue bottom-[-80px] blur-[120px] right-[-80px]"
                    }
                />

            </div>

            <div className='flex flex-col gap-4 justify-center items-start'>
                <div className="flex justify-start items-start  gap-3">
                    <img src={"/Frame.png"} alt="frame" className="w-[24px] h-[24px]" />
                    <p className="text-base font-medium font-inter text-gray2">
                        10 API Calls per second
                    </p>
                </div>
                <div className="flex justify-start items-start  gap-3">
                    <img src={"/Frame.png"} alt="frame" className="w-[24px] h-[24px]" />
                    <p className="text-base font-medium font-inter text-gray2">
                        5 API designs
                    </p>
                </div>
                <div className="flex justify-start items-start  gap-3">
                    <img src={"/Frame.png"} alt="frame" className="w-[24px] h-[24px]" />
                    <p className="text-base font-medium font-inter text-gray2">
                        Community Support
                    </p>
                </div>
                <div className="flex justify-start items-start  gap-3">
                    <img src={"/Frame.png"} alt="frame" className="w-[24px] h-[24px]" />
                    <p className="text-base font-medium font-inter text-gray2">
                        Unlimited revisions
                    </p>
                </div>
            </div>

            <span className='w-full px-6 mt-4'>
                <Button
                    text={"Get Started"}
                    light={true}
                    classes="!font-semibold w-full !text-gradientBlue hover:!text-white !text-base !px-6 !py-4"
                    onClick={() => window.open('https://studio.dataequity.io', '_blank')}
                />
            </span>
        </div>

        {/* <div className='flex relative flex-1 rounded-[16px] overflow-hidden justify-center items-center flex-col gap-[68px] pb-6 shadow-xl -mt-4 sm:mt-0'> 

             <div className='rounded-[16px] flex flex-col justify-center bg-darkBlue items-center gap-6 relative overflow-hidden w-full  py-8 px-6 h-[184px]'>
                <h2 className='font-semibold font-inter text-[24px] text-white z-10'>Team Plan</h2>
                <p className='font-inter font-medium text-base text-center text-white -mt-3 z-10'>For growing businesses scaling their data.</p>
                <p className='font-poppins font-medium text-base text-center text-white z-10'><span className='text-[36px] font-semibold'>$49 </span>/ month</p>
                <Gradient
                    className={
                        "w-[159px] h-[159px] rounded-full bg-gradientBlue bottom-[-50px] blur-[90px] left-[-50px]"
                    }
                />
                <Gradient
                    className={
                        "w-[159px] h-[159px] rounded-full bg-orange top-[-50px] blur-[90px] right-[-50px]"
                    }
                />

            </div> */}

            {/* <div className='flex flex-col gap-4 justify-center items-start'>
                <div className="flex justify-start items-start  gap-3">
                    <img src={"/Frame.png"} alt="frame" className="w-[24px] h-[24px]" />
                    <p className="text-base font-medium font-inter text-gray2">
                        50 API Calls per second
                    </p>
                </div>
                <div className="flex justify-start items-start  gap-3">
                    <img src={"/Frame.png"} alt="frame" className="w-[24px] h-[24px]" />
                    <p className="text-base font-medium font-inter text-gray2">
                        50 API designs
                    </p>
                </div>
                <div className="flex justify-start items-start  gap-3">
                    <img src={"/Frame.png"} alt="frame" className="w-[24px] h-[24px]" />
                    <p className="text-base font-medium font-inter text-gray2">
                        Team collaboration tools
                    </p>
                </div>
                <div className="flex justify-start items-start  gap-3">
                    <img src={"/Frame.png"} alt="frame" className="w-[24px] h-[24px]" />
                    <p className="text-base font-medium font-inter text-gray2">
                        AI enabled API design
                    </p>
                </div>
            </div>

            <span className='w-full px-6 mt-4'>
                <Button
                    text={"Contact Sales"}
                    classes="!font-semibold w-full !text-base !px-6 !py-4"
                    onClick={() => navigate('/contact')}
                />
            </span>
        </div> */}

        <div className='flex relative flex-1 rounded-[16px] overflow-hidden justify-center items-center flex-col gap-[68px] pb-6 shadow-xl'>

            <div className='rounded-[16px] flex flex-col justify-center items-center gap-6 relative overflow-hidden w-full  py-8 px-6 h-[184px]'>
                <h2 className='font-semibold font-inter text-[24px] text-black2'>Enterprise Plan</h2>
                <p className='font-inter font-medium text-base text-center text-black2 -mt-3'>For large businesses with custom needs.</p>
                <p className='font-poppins font-medium text-base text-center text-black2'><span className='text-[36px] font-semibold'>Let's Talk </span></p>
                <Gradient
                    className={
                        "w-[159px] h-[159px] rounded-full bg-orange bottom-[-70px] blur-[120px] left-[-70px]"
                    }
                />
                <Gradient
                    className={
                        "w-[159px] h-[159px] rounded-full bg-orange top-[-80px] blur-[120px] right-[-80px]"
                    }
                />

            </div>

            <div className='flex flex-col gap-4 justify-center items-start'>
                <div className="flex justify-start items-start  gap-3">
                    <img src={"/Frame.png"} alt="frame" className="w-[24px] h-[24px]" />
                    <p className="text-base font-medium font-inter text-gray2">
                        Unlimited APIs
                    </p>
                </div>
                <div className="flex justify-start items-start  gap-3">
                    <img src={"/Frame.png"} alt="frame" className="w-[24px] h-[24px]" />
                    <p className="text-base font-medium font-inter text-gray2">
                        Customer success
                    </p>
                </div>
                <div className="flex justify-start items-start  gap-3">
                    <img src={"/Frame.png"} alt="frame" className="w-[24px] h-[24px]" />
                    <p className="text-base font-medium font-inter text-gray2">
                        24/7 Support
                    </p>
                </div>
                <div className="flex justify-start items-start  gap-3">
                    <img src={"/Frame.png"} alt="frame" className="w-[24px] h-[24px]" />
                    <p className="text-base font-medium font-inter text-gray2">
                        On premise and more...
                    </p>
                </div>
            </div>

            <span className='w-full px-6 mt-4'>
                <Button
                    text={"Contact Sales"}
                    light={true}
                    classes="!font-semibold w-full !text-gradientBlue hover:!text-white !text-base !px-6 !py-4"
                    onClick={() => navigate('/contact')}
                />
            </span>
        </div>

    </div>
</div>
  )
}

export default Packages
